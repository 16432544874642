import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { NeoAlertData } from '@app/core/models/alert.model';


@Component({
  selector: 'neo-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AlertComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NeoAlertData = {},
    public snackBarRef: MatSnackBarRef<AlertComponent>
  ) {
    this.data.title = this.data.title || 'Success!';
    this.data.type = this.data.type || 'success';
    this.data.hideCloseIcon = !!this.data.hideCloseIcon;
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
