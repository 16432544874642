import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { b2cPolicies } from '@app/core/settings/b2c-config';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService, MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest
} from '@azure/msal-browser';
import { asapScheduler, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppModeService } from './services/app-mode.service';
import { LoaderService } from './services/loader.service';
import { UiService } from './services/ui.service';
import { IntercomService } from './services/intercom.service';

interface Payload extends AuthenticationResult {
  idTokenClaims: {
    tfp?: string;
  };
}

@Component({
  selector: 'app-neo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'NeoOne';
  isIframe = false;
  loginDisplay = false;
  _loader$!: boolean;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loaderService: LoaderService,
    private appModeService: AppModeService,
    private uiService: UiService,
    private intercomService: IntercomService
  ) {
    this.uiService.registerSvgIcons();
  }

  ngOnInit(): void {
    this.appModeService.initAppModeTracking();
    this.registerLoader();
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.handleMsalLogin();
  }

  handleMsalLogin(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        // Active account has now been set properly, enable the Intercom now
        void this.intercomService.checkAndEnableIntercom();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload: Payload = result.payload as AuthenticationResult;
        if (payload.idTokenClaims.tfp === b2cPolicies.names.editProfile) {
          window.alert(
            'Profile has been updated successfully. \nPlease sign-in again.'
          );
          return this.logout();
        }
        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Add your auth error handling logic here
      });
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout(): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  editProfile(): void {
    const editProfileFlowRequest = {
      scopes: ['openid'],
      authority: b2cPolicies.authorities.editProfile.authority,
    };

    this.login(editProfileFlowRequest);
  }

  registerLoader(): void {
    this.loaderService._loading$
      .pipe(takeUntil(this._destroying$))
      .subscribe((loaderValue: boolean) => {
        asapScheduler.schedule(() => {
          this._loader$ = loaderValue;
        });
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
