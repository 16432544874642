import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public _loading$ = new BehaviorSubject<boolean>(false);

  public startLoading(): void {
    this._loading$.next(true);
  }

  public stopLoading(): void {
    this._loading$.next(false);
  }
}
