export interface FilterValue {
  id: string;
  label: string;
  children?: FilterValue[];
  selected?: boolean;
  type: FilterType;
  startDate?: Date;
  endDate?: Date;
}

export enum FilterType {
  collection = 'collection',
  checkbox = 'checkbox',
  neoToggle = 'neoToggle',
  dateRange = 'dateRange',
  date = 'date',
}
