
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { NeoToastComponent } from '@app/shared/components/neo-toast/neo-toast.component';
import { NeoToastData, NeoToastType } from '@app/shared/components/neo-toast/neo-toast.models';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  private duration: number = 3000; // 3 Seconds
  private verticalPosition: MatSnackBarVerticalPosition = 'top';
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';

  constructor(private snackBar: MatSnackBar, private domSanitizer: DomSanitizer) { }

  //#region v1 implementation using AlertComponent

  public showSuccess(message: string, isHtml: boolean = false, title: string = 'Success!'): void {
    const data = isHtml ? { html: this.domSanitizer.bypassSecurityTrustHtml(message) } : { message };

    this.snackBar.openFromComponent(AlertComponent, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: `neo-alert-panel`,
      data: { ...data, type: 'success', title },
    });
  }


  public showWarning(message: string, isHtml: boolean = false, title: string = 'Warning!'): void {
    const data = isHtml ? { html: this.domSanitizer.bypassSecurityTrustHtml(message) } : { message };

    this.snackBar.openFromComponent(AlertComponent, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: `neo-alert-panel`,
      data: { ...data, type: 'warning', title },
    });
  }

  public showError(message: string, isHtml: boolean = false, title: string = 'Error!'): void {
    const data = isHtml ? { html: this.domSanitizer.bypassSecurityTrustHtml(message) } : { message };

    this.snackBar.openFromComponent(AlertComponent, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: `neo-alert-panel`,
      data: { ...data, type: 'error', title },
    });
  }

  //#endregion v1 implementation using AlertComponent

  //#region v2 using NeoToastComponent

  public neoToastShowSuccess(message: string, isHtml: boolean = false, title: string = ''): void {
    const data: NeoToastData = new NeoToastData();
    data.title = title;
    data.message = message;
    data.html = isHtml ? this.domSanitizer.bypassSecurityTrustHtml(message) : undefined;
    data.type = NeoToastType.success;

    this.neoToastShow(data);
  }

  public neoToastShowWarning(message: string, isHtml: boolean = false, title: string = ''): void {
    const data: NeoToastData = new NeoToastData();
    data.title = title;
    data.message = message;
    data.html = isHtml ? this.domSanitizer.bypassSecurityTrustHtml(message) : undefined;
    data.type = NeoToastType.warning;

    this.neoToastShow(data);
  }

  public neoToastShowError(message: string, isHtml: boolean = false, title: string = ''): void {
    const data: NeoToastData = new NeoToastData();
    data.title = title;
    data.message = message;
    data.html = isHtml ? this.domSanitizer.bypassSecurityTrustHtml(message) : undefined;
    data.type = NeoToastType.error;

    this.neoToastShow(data);
  }

  public neoToastShow(data: NeoToastData): void {
    this.snackBar.openFromComponent(NeoToastComponent, {
      duration: data.duration ?? this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: `neo-toast-panel`,
      data: data
    });
  }

  //#endregion v2 using NeoToastComponent

}
