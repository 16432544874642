import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { DateRange } from '@app/core/models/date-range.model';
import { MessagingService } from '@app/services/messaging.service';
import * as lz from 'lz-string';
import { Subscription, asapScheduler, asyncScheduler } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FieldsConfig, NeoCardFooterButtons, NeoCardTitle } from '../neo-card/neo-card-values';
import { FilterValue } from '../neo-flyout-filter/neo-flyout-filter-value';
import { NeoTableHeaderButton, PersistUISettingsParams, QueryParams, SearchChangeEvent } from '../neo-table/neo-table-value';
import { NeoTableService } from '../neo-table/neo-table.service';
import { NeoCardListView, StorageLocation } from './neo-card-list-values';
import { NeoCardListService } from './neo-card-list.service';

@Component({
  selector: 'neo-card-list',
  templateUrl: './neo-card-list.component.html',
  styleUrls: ['./neo-card-list.component.scss']
})
export class NeoCardListComponent<T> implements OnChanges, OnInit, AfterViewInit, OnDestroy {

  /* Neo Card List props */
  @Input() dataSourceFunction!: (refetchHeaderButtonsData?: boolean) => void;
  @Input() dataSource: MatTableDataSource<T> = new MatTableDataSource([] as T[]);
  @Input() title: NeoCardTitle = {} as NeoCardTitle;
  @Input() fieldConfig!: FieldsConfig[];
  @Input() currentViewCols: NeoCardListView = NeoCardListView.listView;
  @Input() columnsGap: string | number = '10px';
  @Input() enableHeaderButtons: boolean = false;
  @Input() dateRangeCtrl: FormGroup = this.getDateRangeCtrl();
  @Input() headerButtons: NeoTableHeaderButton[] = [];
  @Input() enableSearch: boolean = false;
  @Input() enableDateRange: boolean = false;
  @Input() defaultDateRange: DateRange | (() => DateRange) = { start: new Date(), end: new Date() };
  @Input() enableToggleView: boolean = false;
  @Input() listViewCols: NeoCardListView = NeoCardListView.listView;
  @Input() gridViewCols: NeoCardListView = NeoCardListView.gridView;
  @Input() isBusy: boolean = false;
  @Input() dateRange: DateRange = { start: new Date(), end: new Date() };
  @Input() dateRangeMin?: Date | null;
  @Input() dateRangeMax?: Date | null;
  @Input() endDate: string = '';
  @Input() neoCardFooterButtons: NeoCardFooterButtons[] = [];
  @Input() enableNeoCardFooterButtons: boolean = false;
  @Input() cardListName: string = '';
  @Input() noPagination: boolean = false;
  @Input() searchDebounce: number = 300; // milli second
  @Input() searchCtrl: FormControl = new FormControl('');

  @Input() persistDateRange: boolean = false;

  @Input() refetchOnSearchChange: boolean = true;
  @Input() refetchOnApplyFilters: boolean = true;
  @Input() refetchOnResetFilters: boolean = true;
  @Input() refetchOnDateRangeChange: boolean = true;
  @Input() refetchOnSortChange: boolean = true;
  @Input() refetchOnPagination: boolean = true;

  @Input() filtersStorageLocation: StorageLocation = StorageLocation.queryParams;

  @Output() searchChange = new EventEmitter<SearchChangeEvent>();
  @Output() dateChange = new EventEmitter<DateRange>();
  @Output() viewChange = new EventEmitter<NeoCardListView>();
  @Output() filterSelectionChange = new EventEmitter<FilterValue[]>();
  @Output() applyFilters = new EventEmitter<FilterValue[]>();
  @Output() resetFilters = new EventEmitter<FilterValue[]>();

  @ViewChild('neoCardPaginatorContainer', { static: false, read: ElementRef }) neoCardPaginatorContainer!: ElementRef;
  @ViewChild('search') searchInput!: ElementRef;

  @ContentChild(MatPaginator, { static: false, read: ElementRef }) paginatorRef!: ElementRef;
  @ContentChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  page: number = 0;
  subscriptions = new Subscription();
  searchApplying: boolean = false;
  persistedFilters: FilterValue[] = [];

  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private router: Router,
    private messagingService: MessagingService,
    private renderer: Renderer2,
    private neoCardListService: NeoCardListService
  ) { }

  static validateDateRange(absCtrl: AbstractControl): ValidationErrors {
    const form = absCtrl as unknown as FormGroup;
    const { start, end } = form.value;

    if (form.dirty && !start) {
      return { emptyFromDate: true };
    }
    if (form.dirty && !end) {
      return { emptyToDate: true };
    }
    if (form.dirty && start > end) {
      return { endDateLessThanStartDate: true };
    }
    return {};
  }

  get invalidDateRange(): boolean {
    return this.dateRangeCtrl?.controls?.dateRange.hasError('emptyFromDate') ||
      this.dateRangeCtrl?.controls?.dateRange.hasError('emptyToDate') ||
      this.dateRangeCtrl?.controls?.dateRange.hasError('endDateLessThanStartDate');
  }

  ngOnInit(): void {
    this.registerSearchChange();
    this.registerQueryParamsChange();

    asyncScheduler.schedule(() => {
      this.dateRangeCtrl.patchValue({ dateRange: this.dateRange });
    });
  }

  ngOnChanges(): void {
    this.columnsGap = typeof this.columnsGap === 'number' ? `${this.columnsGap}px` : this.columnsGap;
  }

  ngAfterViewInit(): void {
    if (this.paginatorRef) this.setupPaginator();
    this.updateUISettingsFromStorage();
  }

  registerQueryParamsChange(): void {
    let init = true;
    const sub = this.actRoute.queryParams.subscribe((params) => {
      this.patchValuesFromQueryParams(params, init);
      init = false;
    });
    this.subscription.add(sub);
    // init query params
    this.changeQueryParams(this.actRoute.snapshot.queryParams);
  }

  registerSearchChange(): void {
    let init = true;
    const sub = this.searchCtrl.valueChanges
      .pipe(debounceTime(this.searchDebounce), distinctUntilChanged())
      .subscribe(
        (searchText: string) => {
          this.persistUISettings({ searchText });
          this.searchChange.emit({ value: searchText, init });
          if ((init || this.refetchOnSearchChange) && typeof this.dataSourceFunction === 'function') {
            this.dataSourceFunction(init);
          }
          init = false;
        },
        (err: Error) => {
          init = false;
          this.messagingService.showError(err.message)
        }
      );
    this.subscription.add(sub);
  }

  updateUISettingsFromStorage(): void {
    const uiSettings = this.neoCardListService.getUISettings(this.cardListName);
    if (uiSettings) {
      asapScheduler.schedule(() => {
        if (this.page) {
          this.paginator.pageIndex = this.page;
        }

        if (uiSettings?.pagination) {
          const paginator = uiSettings?.pagination as PageEvent;
          this.paginator.pageSize = paginator.pageSize;
        }

        if ((this.persistDateRange && uiSettings?.dateRange) || this.defaultDateRange) {
          const dateRange = (this.persistDateRange && uiSettings?.dateRange) || (typeof this.defaultDateRange === 'function' ? this.defaultDateRange() : this.defaultDateRange);
          this.dateRangeCtrl.controls.dateRange.patchValue(dateRange);

          if (!this.dateRangeCtrl.controls.dateRange?.value?.start) {
            this.dateRangeCtrl.controls.dateRange.setErrors({ 'emptyFromDate': true });
          } else if (!this.dateRangeCtrl.controls.dateRange?.value?.end) {
            this.dateRangeCtrl.controls.dateRange.setErrors({ 'emptyToDate': true });
          } else if (this.dateRangeCtrl.controls.dateRange?.value?.start > this.dateRangeCtrl.controls.dateRange?.value?.end) {
            this.dateRangeCtrl.controls.dateRange.setErrors({ 'endDateLessThanStartDate': true });
          }
        }

        if (this.filtersStorageLocation === StorageLocation.localStorage && uiSettings?.filters) {
          try {
            const paramsFilters = JSON.parse(lz.decompressFromEncodedURIComponent(uiSettings.filters as string || '') || '[]') as FilterValue[];
            if (paramsFilters?.length) {
              this.persistedFilters = paramsFilters;
              this.applyFilters.emit(paramsFilters);
            }
          } catch (err) {
            this.messagingService.showError((err as Error)?.message);
          }
        }

      });
    }
  }

  persistUISettings({ searchText, sort, pagination, filters, dateRange }: PersistUISettingsParams): void {
    const uiSettings = this.neoCardListService.getUISettings(this.cardListName) || {};
    const qParams = {} as QueryParams;

    searchText = searchText || this.searchCtrl.value;

    if (filters !== null && filters !== undefined) {
      if (this.filtersStorageLocation === StorageLocation.localStorage) {
        uiSettings.filters = lz.compressToEncodedURIComponent(filters);
      } else {
        qParams.filters = lz.compressToEncodedURIComponent(filters);
      }
    }

    if ((pagination !== null && pagination !== undefined) || (this.paginator !== null && this.paginator !== undefined)) {
      const { pageIndex, pageSize, length } = this.paginator;
      pagination = pagination || { pageIndex, pageSize, length };
      uiSettings.pagination = pagination;
      qParams.page = +pagination.pageIndex + 1;
    }

    if (searchText !== null && searchText !== undefined) {
      qParams.searchText = searchText;
    }

    if (dateRange !== null && dateRange !== undefined) {
      uiSettings.dateRange = dateRange;
    }

    if (Object.values(qParams).length > 0) {
      this.changeQueryParams({ ...this.actRoute.snapshot.queryParams, ...qParams });
    }

    this.neoCardListService.setUISettings(this.cardListName, uiSettings);
  }

  changeQueryParams(qParams: QueryParams = {}): void {
    const queryParam: QueryParams = { searchText: qParams.searchText || this.searchCtrl.value };
    const snapQueryParams = this.actRoute.snapshot.queryParams;

    if (!this.noPagination) {
      queryParam.page = (+qParams.page || +(this.paginator?.pageIndex || 0) + 1)?.toString();
    }

    if (this.filtersStorageLocation !== StorageLocation.localStorage && qParams.filters) {
      queryParam.filters = qParams.filters;
    }

    if (NeoTableService.isJSONEqual(queryParam, snapQueryParams)) return;

    this.router
      .navigate(['.'], {
        relativeTo: this.actRoute,
        queryParams: NeoTableService.deleteEmptyValues({ ...snapQueryParams, ...queryParam })
      })
      .catch((err: Error) => this.messagingService.showError(err.message));
  }

  patchValuesFromQueryParams(params: QueryParams, init: boolean = false): void {
    const paramsSearchText = params.searchText || '';
    const pageIndex = params?.page ? (+params?.page - 1) : 0;

    if (paramsSearchText !== this.searchCtrl.value || init) {
      const searchText = params?.searchText || '';
      this.searchCtrl.setValue(searchText);
    }

    if (pageIndex || pageIndex === 0) {
      if (this.paginator?.pageIndex !== undefined && this.paginator?.pageIndex !== null) {
        this.paginator.pageIndex = +pageIndex;
      } else {
        this.page = +pageIndex || 0;
      }
    }

    if (this.filtersStorageLocation !== StorageLocation.localStorage) {
      try {
        const paramsFilters = JSON.parse(lz.decompressFromEncodedURIComponent(params.filters as string || '') || '[]') as FilterValue[];
        if (paramsFilters?.length) {
          this.persistedFilters = paramsFilters;
          this.applyFilters.emit(paramsFilters);
        }
      } catch (err) {
        this.messagingService.showError((err as Error)?.message);
      }
    }
  }

  setupPaginator(): void {
    this.renderer.appendChild(this.neoCardPaginatorContainer?.nativeElement, this.paginatorRef?.nativeElement);

    const sub = this.paginator?.page?.subscribe?.(
      (event: PageEvent) => {
        this.persistUISettings({ pagination: event });

        if (this.refetchOnPagination && typeof this.dataSourceFunction === 'function') {
          this.dataSourceFunction();
        } else {
          asapScheduler.schedule(() => {
            const scrollOpts = { behavior: 'smooth', block: 'start', inline: 'nearest', };
            this.neoCardPaginatorContainer?.nativeElement?.scrollIntoView?.(scrollOpts);
          });
        }
      },
      (err: Error) => this.messagingService.showError(err.message)
    );
    this.subscription.add(sub);
  }

  onDateRangeClose(): void {
    const { dateRange } = this.dateRangeCtrl.value;
    if (this.persistDateRange) this.persistUISettings({ dateRange });

    if (this.dateRangeCtrl.dirty && !this.invalidDateRange) {
      dateRange.start = NeoTableService.getFormattedDateStr(dateRange.start);
      dateRange.end = NeoTableService.getFormattedDateStr(dateRange.end);
      this.dateChange.emit(dateRange);

      if (this.refetchOnDateRangeChange && typeof this.dataSourceFunction === 'function') {
        this.dataSourceFunction();
      }
    }
  }

  headerButtonClickHandler(index: number): void {
    const button = this.headerButtons[index];
    if (typeof button?.onClick === 'function') {
      button.onClick();
    }
  }

  getDateRangeCtrl(): FormGroup {
    return this.fb.group({
      dateRange: this.fb.group(
        {
          start: new FormControl(new Date()),
          end: new FormControl(new Date()),
        },
        { validators: NeoCardListComponent.validateDateRange.bind(this) }
      ),
    });
  }

  viewChanged(event: MatButtonToggleChange): void {
    this.viewChange.emit(event.value as NeoCardListView);
  }

  selectionChanged(event: FilterValue[]): void {
    this.filterSelectionChange.emit(event);
  }

  applyFilter(event: FilterValue[]): void {
    this.persistUISettings({ filters: JSON.stringify(event) });
    this.applyFilters.emit(event);

    if (this.refetchOnApplyFilters && typeof this.dataSourceFunction === 'function') {
      this.dataSourceFunction();
    }
  }

  resetAllFilter(event: FilterValue[]): void {
    this.persistUISettings({ filters: JSON.stringify(event) });
    this.resetFilters.emit(event);

    if (this.refetchOnResetFilters && typeof this.dataSourceFunction === 'function') {
      this.dataSourceFunction();
    }
  }

  unique = (index: number): number => index;

  ngOnDestroy(): void {
    this.subscription?.unsubscribe?.();
  }
}
