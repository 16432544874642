import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'neo-toggle-button',
  templateUrl: './neo-toggle-button.component.html',
  styleUrls: ['./neo-toggle-button.component.scss'],
})
export class NeoToggleButtonComponent {
  @Input() activeLabel: string = 'INACTIVE';
  @Input() inactiveLabel: string = 'ACTIVE';
  @Input() value: boolean = false;
  @Input() disabled: boolean = false;
  @Input() small: boolean = false;

  @Output() valueChange = new EventEmitter<boolean>();

  valueChanged(value: boolean): void {
    this.valueChange.emit(value);
  }
}
