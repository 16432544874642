import { Pipe, PipeTransform } from '@angular/core';
import { FilterValue } from '../components/neo-flyout-filter/neo-flyout-filter-value';

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(values?: FilterValue[], args?: string): FilterValue[] | undefined {
    if (!values || !args) return values;
    return values.filter((data: FilterValue): boolean => {
      return data?.label
        ?.toLowerCase?.()
        ?.includes(args?.toLowerCase?.());
    });
  }
}
