<div class="card mat-elevation-z4">

  <!-- Card Header -->
  <div class="card-header" *ngIf="title">
    <div class="left-section" *ngIf="title.left">
      <p class="mat-caption">{{title.left.label}}</p>
      <p class="mat-subtitle-1">{{leftTitle}}</p>
    </div>
    <div class="right-section" *ngIf="title.right">
      <p class="mat-caption">{{title.right.label}}</p>
      <p class="mat-subtitle-1">{{rightTitle}}</p>
    </div>
  </div>

  <div #row class="card-body">

    <!-- KeyValue View -->
    <div *ngFor="let field of fieldConfig; let idx = index; trackBy: unique">

      <div class="text-section" *ngIf="[fieldTypeEnum.textbox, fieldTypeEnum.datepicker].includes(field.type)">
        <div class="row">
          <div class="mat-body-2 label">{{ field.label }} &nbsp;</div>

          <div class="mat-body-1 value" *ngIf="[fieldTypeEnum.textbox].includes(field.type)">
            {{ dataSource[field.valueKey] }}
          </div>

          <div class="mat-body-1 value" *ngIf="[fieldTypeEnum.datepicker].includes(field.type)">
            {{ dataSource[field.valueKey] | neoLongDate }}
          </div>
        </div>
      </div>

      <div class="document-section" *ngIf="field.type === fieldTypeEnum.media">
        <div *ngFor="let media of dataSource[field.valueKey]; let mIdx = index; trackBy: unique">
          <img class="image mat-elevation-z6" ngClass.lt-md="mobile-view" #image
            [src]="media?.thumbnailPath | secureImage: media.type | async" alt=""
            (click)="handleThumbnailClick(dataSource[field.valueKey], mIdx, media.type)"
            (keyup)="handleThumbnailClick(dataSource[field.valueKey], mIdx, media.type)">
          <div *ngIf="media.type === neoDocType.pdf && !image.src.includes('pdf.png')" class="pdf-indicator"
            [style.background-image]="'url(' + pdfThumbnail +')'"></div>
        </div>
      </div>
    </div>

    <!-- Card Footer Buttons -->
    <div class="bottom-row" *ngIf="enableNeoCardFooterButtons">
      <button *ngFor="let button of neoCardFooterButtons; let idx = index; trackBy: unique" mat-icon-button class="button edit">
        <mat-icon color="primary" class="close-icon" #tooltip="matTooltip" [matTooltip]="button.tooltip"
          matTooltipClass="dark-tooltip" matTooltipPosition="above">
          {{button.icon}}
        </mat-icon>
      </button>
    </div>
  </div>
</div>
