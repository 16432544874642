<div class="main-container">
  <div class="loading-spinner" *ngIf="isBusy">
    <mat-spinner *ngIf="isBusy"></mat-spinner>
    <div class="loader-text">Loading...</div>
  </div>

  <div class="header-container">
    <div class="left-container" ngStyle.lt-md="flex-direction: column">
      <div *ngIf="enableSearch" class="search-container">
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <input #search matInput type="search" class="search-input" [formControl]="searchCtrl" data-test="searchText" />
          <mat-icon *ngIf="!search.value; else clearBtn;" matSuffix>search</mat-icon>

          <ng-template #clearBtn>
            <button matSuffix mat-icon-button (click)="searchCtrl.setValue('')" matTooltip="Clear" matTooltipClass="dark-tooltip">
              <mat-icon>close</mat-icon>
            </button>
          </ng-template>
        </mat-form-field>
      </div>

      <div *ngIf="enableDateRange" class="date-range-container" ngClass.lt-md="mobile-view">
        <form [formGroup]="dateRangeCtrl">
          <div class="datePicker-outer-container">
            <div class="datePicker-inner-container" [ngClass]="{ 'errored': invalidDateRange }">
              <mat-date-range-input formGroupName="dateRange" [min]="dateRangeMin" [max]="dateRangeMax" (click)="$event.stopPropagation();" [rangePicker]="picker">
                <input readonly matStartDate formControlName="start" placeholder="Start date" (click)="picker.open()">
                <input readonly matEndDate formControlName="end" placeholder="End date" (click)="picker.open()">
              </mat-date-range-input>
              <mat-datepicker-toggle color="primary" (click)="$event.stopPropagation();" matSuffix [for]="picker">
                <mat-icon color="primary" matDatepickerToggleIcon>date_range</mat-icon>
              </mat-datepicker-toggle>
              <mat-date-range-picker (closed)="onDateRangeClose()" (click)="$event.stopPropagation();" #picker></mat-date-range-picker>
            </div>
            <mat-error *ngIf="dateRangeCtrl.controls.dateRange.hasError('emptyFromDate')">Start Date is required*</mat-error>
            <mat-error *ngIf="dateRangeCtrl.controls.dateRange.hasError('emptyToDate')">End Date is required*</mat-error>
            <mat-error *ngIf="dateRangeCtrl.controls.dateRange.hasError('endDateLessThanStartDate')">'End Date' must be greater than 'Start Date'</mat-error>
          </div>
        </form>
      </div>
    </div>

    <div class="header-buttons-container mat-body-2" ngStyle.xs="width: 100%">
      <div *ngIf="enableToggleView" class="toggle-view-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="currentViewCols" (change)="viewChanged($event)">
          <mat-button-toggle [value]="listViewCols" matTooltip="List View" matTooltipClass="dark-tooltip"><mat-icon>format_align_justify</mat-icon></mat-button-toggle>
          <mat-button-toggle [value]="gridViewCols" matTooltip="Grid View" matTooltipClass="dark-tooltip"><mat-icon>dashboard</mat-icon></mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div *ngIf="enableHeaderButtons" class="header-buttons">
        <div *ngFor="let button of headerButtons; let idx = index; trackBy: unique">
          <a [attr.href]="button.redirectURL" (click)="headerButtonClickHandler(idx)" [fxHide.lt-md]="button.hideInMobileView">
            <neo-button [disabled]="button.disabled || false" *ngIf="button.type ==='default'" buttonType="primary" [id]="'headerButton'+button.id"
              data-test="header-button" class="header-button" [prefixIcon]="button.icon" [text]="button.label" type="button">
            </neo-button>
          </a>
          <neo-flyout-filter class="header-button flyout-filter" *ngIf="button.type === 'filter'"
            [filters]="button.filterData || []" [openerButtonLabel]="button?.label" [fxHide.lt-md]="button.hideInMobileView" [disabled]="button.disabled"
            [openerButtonColor]="'primary'" (change)="selectionChanged($event)" (applyFilters)="applyFilter($event)" (resetFilters)="resetAllFilter($event)">
          </neo-flyout-filter>
        </div>
      </div>
    </div>
  </div>

  <div class="masonry-container" [style.columns]="currentViewCols" [style.columnsGap]="columnsGap">
    <div class="masonry-item" *ngFor="let item of dataSource.filteredData; let idx = index; trackBy: unique">
      <neo-card [title]="title" [dataSource]="item" [neoCardFooterButtons]="neoCardFooterButtons"
        [fieldConfig]="fieldConfig" [enableNeoCardFooterButtons]="enableNeoCardFooterButtons">
      </neo-card>
    </div>
  </div>

  <div *ngIf="!isBusy && !dataSource.filteredData.length" class="no-data-found mat-headline-6">
    <mat-icon>search_off</mat-icon>
    <span>No records found.</span>
  </div>

  <div class="paginator-container" #neoCardPaginatorContainer></div>

</div>
