<div fxLayout="column" class="neo-toast-component">
  <div fxLayout="row" fxLayoutAlign="start center" class="neo-toast-container">
    <span class="icon-container" [ngClass]="data.type">
      <ng-container [ngSwitch]="data.type">
        <span *ngSwitchCase="NeoToastType.info"><mat-icon svgIcon="neo_snackbar_info" class="toast-icon"></mat-icon></span>
        <span *ngSwitchCase="NeoToastType.success"><mat-icon svgIcon="neo_snackbar_success" class="toast-icon"></mat-icon></span>
        <span *ngSwitchCase="NeoToastType.warning"><mat-icon svgIcon="neo_snackbar_warning" class="toast-icon"></mat-icon></span>
        <span *ngSwitchCase="NeoToastType.error"><mat-icon svgIcon="neo_snackbar_error" class="toast-icon"></mat-icon></span>
      </ng-container>
    </span>
    <ng-container *ngIf="data.title">
      <div class="title-container">
        <span class="title">
          {{ data.title }}
        </span>
      </div>
    </ng-container>
    <div class="message-container">
      <ng-container *ngIf="!data.html; else html">
        <span>{{ data.message }}</span>
      </ng-container>
      <ng-template #html>
        <div [innerHtml]="data.html"></div>
      </ng-template>
    </div>
    <ng-container *ngIf="data.showCloseIcon">
      <span fxFlex></span>
      <span><button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button></span>
    </ng-container>
  </div>
</div>

