import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { User } from '@app/core/models';
import { amplitudeApiKey, amplitudeServerZone } from '@app/core/settings/app.config';
import { NeoTrackEventStatus, NeoTrackEventAction, NeoTrackEventComponent } from './amplitude.service.models';
import { OrganizationFeatureFlags } from '@app/core/models/organization-feature.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  private amplitudeInstance: amplitude.Types.BrowserClient | undefined = undefined;
  private active: boolean = true;

  constructor(private authService: AuthenticationService) {
  }

  public initialise(): void {
    try {
      const apiKey: string = AmplitudeService.getApiKey();
      const options: amplitude.Types.BrowserOptions = AmplitudeService.getOptions();
      if (apiKey) {
        this.amplitudeInstance = amplitude.createInstance();
        if (this.amplitudeInstance) {
          this.amplitudeInstance.init(apiKey, undefined, options);
        }
      }
    }
    catch (e) { }
  }

  private static getApiKey(): string {
    return amplitudeApiKey();
  }

  private static getServerZone(): amplitude.Types.ServerZoneType {
    return amplitudeServerZone() as amplitude.Types.ServerZoneType;
  }

  private static getOptions(): amplitude.Types.BrowserOptions {

    // ------------------ WARNING ----------------------------
    // When testing, the page would get stuck until these were turned off by default
    // So be very carefull if they are turned back on and make sure it is tested.
    // ------------------ WARNING ----------------------------
    return {
      autocapture: {
        attribution: false,
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false,
      },
      serverZone: AmplitudeService.getServerZone()
    };
  }

  public setActiveUser(featureFlags?: OrganizationFeatureFlags): void {
    if (this.amplitudeInstance && this.authService) {
      const activeUser: User | null = this.authService.getActiveUser();
      if (activeUser) {
        this.amplitudeInstance.setUserId(activeUser.userId);
        if (this.authService) {
          const activeUser: User | null = this.authService.getActiveUser();
          if (activeUser) {
            const activeUserIdentity: amplitude.Types.Identify = new amplitude.Identify();
            activeUserIdentity.set('roles', activeUser.roles);
            activeUserIdentity.set('organisationId', activeUser.extensionOrganizationId);
            activeUserIdentity.set('organisationName', activeUser.extensionOrganizationName);
            if (featureFlags && featureFlags.data) {
              activeUserIdentity.set('featureFlagsAvailable', true);
              for (const [key, value] of Object.entries(featureFlags.data)) {
                activeUserIdentity.set(key, value as amplitude.Types.ValidPropertyType);
              }
            }
            else {
              activeUserIdentity.set('featureFlagsAvailable', false);
            }
            this.amplitudeInstance.identify(activeUserIdentity);
          }
        }
      }
    }
  }

  // ------------------ INFO ----------------------------
  // The following are the main tracking methods
  // The events to be tracked and the structure of the tracking is defined in: https://neointelligence.atlassian.net/wiki/spaces/Product/pages/545947651/Analytics
  //
  // Event Naming Conventions:
  // [Action]_[ComponentName]_[Location]_[State]
  // Action: What the user is doing (e.g. click, submit, view)
  // ComponentName: What object the action is applied to (e.g. button, page, form).
  // Location: Where the action occurs (e.g, homepage, mobile)
  // Status: The result of the action (e.g. success, error)
  //
  // ------------------ INFO ----------------------------

  public track(action: NeoTrackEventAction, componentName: string, location: string, status: NeoTrackEventStatus, properties?: Record<string, any>): void {
    if(this.active && this.amplitudeInstance) {
      if (!properties) {
        properties = {};
      }
      let event = ``;
      if(action) {
        event += action;
        properties.action = action;
      }
      if(componentName) {
        if(event) {
          event += `_`;
        }
        event += componentName;
        properties.componentName = componentName;
      }
      if(location) {
        if(event) {
          event += `_`;
        }
        event += location;
        properties.location = location;
      }
      if(status) {
        if(event) {
          event += `_`;
        }
        event += status;
        properties.status = status;
      }
      this.amplitudeInstance.track(event, properties);
    }
  }

  public trackPageView(url: string): void {
    if(this.active && this.amplitudeInstance) {
      const page = this.extractUrlPath(url);
      const queryParams = this.extractQueryParams(url);
      const properties = { page: page, properties: queryParams };
      const event = `${NeoTrackEventAction.PageView}_${page}`;
      this.amplitudeInstance.track(event, properties);
    }
  }

  public reset(): void {
    if (this.active && this.amplitudeInstance) {
      this.amplitudeInstance.reset();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private extractUrlPath(url: string): string {
    const parsedUrl = new URL(url, window.location.origin);
    const path = parsedUrl.pathname;
    return path;
  }

  // eslint-disable-next-line class-methods-use-this
  private extractQueryParams(url: string): Record<string, string> {
    const queryParams: Record<string, string> = {};
    if(url) {
      const queryString = url.split('?')[1];
      if (queryString) {
        const pairs = queryString.split('&');
        for (const pair of pairs) {
          const [key, value] = pair.split('=');
          queryParams[key] = decodeURIComponent(value || '');
        }
      }
    }
    return queryParams;
  }

}
