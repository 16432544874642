import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeComma'
})
export class RemoveCommaPipe implements PipeTransform {

  // eslint-disable-next-line class-methods-use-this
  transform(value: string|null): string {
    if (value !== undefined && value !== null) {
      return value.replace(/,/g, "");
    }
    return "";
  }
}