import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SecureImage } from '@app/core/models/neo-media-viewer.model';
import { Observable } from 'rxjs/Observable';
import { NeoMediaViewerService } from '../components/neo-media-viewer/neo-media-viewer.service';
import { defaultPDFThumbnail, loading } from './loading';


@Pipe({
  name: 'secureImage'
})
export class SecureImagePipe implements PipeTransform {

  constructor(private neoMediaViewerService: NeoMediaViewerService, private sanitizer: DomSanitizer) { }

  transform(url: string, mediaType: string = 'PHOTO'): Observable<SafeUrl> {
    return new Observable<SafeUrl>((observer) => {
      // Show while image is loading
      observer.next(this.sanitizer.bypassSecurityTrustUrl(loading));
      this.neoMediaViewerService.getSecureImage(url).subscribe(({ base64Url }: SecureImage) => {
        const safeURL = this.sanitizer.bypassSecurityTrustUrl(base64Url);
        observer.next(safeURL);
      }, () => {
        if (['PDF'].includes(mediaType?.toUpperCase())) {
          observer.next(defaultPDFThumbnail);
        } else {
          observer.next();
        }
      });
      return observer;
    });
  }
}
