import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationService } from '@app/services/navigation.service';
import { MsalInterceptor } from '@azure/msal-angular';
import { Observable } from 'rxjs';

@Injectable()
export class NeoHttpInterceptor extends MsalInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (NavigationService.isFormSubmissionInKioskMode(window.location.href)) {
      const accessToken = localStorage.getItem('access_token') as string;
      const clonedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return next.handle(clonedReq);
    }

    return super.intercept(req, next);
  }
}
