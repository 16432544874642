import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})

export class SortByPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(array: any[], sortBy: string, order: 'asc' | 'desc' = 'asc'): any[] {
    if (!array || !sortBy) return array;
    return array.sort((a, b) => {
      if (a[sortBy] > b[sortBy]) {
        return order === 'asc' ? 1 : -1;
      }
      if (a[sortBy] < b[sortBy]) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }
}
