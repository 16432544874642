import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import {
  MatLegacyColumnDef as MatColumnDef,
  MatLegacyHeaderRowDef as MatHeaderRowDef,
  MatLegacyRowDef as MatRowDef,
  MatLegacyTable as MatTable,
  MatLegacyTableDataSource as MatTableDataSource
} from '@angular/material/legacy-table';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Breakpoint } from '@app/core/models/app-mode.model';
import { DateRange } from '@app/core/models/date-range.model';
import { MessagingService } from '@app/services/messaging.service';
import {
  NeoTableHeaderButton,
  PersistUISettingsParams,
  QueryParams,
  SearchChangeEvent,
  UISettings
} from '@app/shared/components/neo-table/neo-table-value';
import { NeoTableService } from '@app/shared/components/neo-table/neo-table.service';
import * as lz from 'lz-string';
import { Subscription, asapScheduler } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StorageLocation } from '../neo-card-list/neo-card-list-values';
import { NeoCardListComponent } from '../neo-card-list/neo-card-list.component';
import { FilterValue } from '../neo-flyout-filter/neo-flyout-filter-value';

@Component({
  selector: 'neo-table',
  templateUrl: './neo-table.component.html',
  styleUrls: ['./neo-table.component.scss'],
})
export class NeoTableComponent<T> implements AfterContentInit, AfterViewInit, AfterViewChecked, OnDestroy, OnInit {
  /* Material table props */
  @Input() dataSourceFunction!: () => void;
  @Input() dataSource: MatTableDataSource<T> = new MatTableDataSource();
  @Input() fixedLayout: boolean = false;

  @Input() refetchOnSearchChange: boolean = true;
  @Input() refetchOnApplyFilters: boolean = true;
  @Input() refetchOnResetFilters: boolean = true;
  @Input() refetchOnDateRangeChange: boolean = true;
  @Input() refetchOnSortChange: boolean = true;
  @Input() refetchOnPagination: boolean = true;

  /* Neo table props */
  @Input() tableName: string = '';
  @Input() hideTable: boolean = false;
  @Input() enableSearch: boolean = false;
  @Input() searchCtrl: FormControl = new FormControl('');
  @Input() dateRangeCtrl: FormGroup = this.getDateRangeCtrl();
  @Input() enableDateRange: boolean = false;
  @Input() defaultDateRange: DateRange | (() => DateRange) = { start: new Date(), end: new Date() };
  @Input() headerButtons: NeoTableHeaderButton[] = [];
  @Input() enableHeaderButtons: boolean = false;
  @Input() matSortRef!: MatSort;
  @Input() matSortDirection!: SortDirection;
  @Input() matSortActive!: string;
  @Input() searchDebounce: number = 300; // milli seconds
  @Input() tableContainerClass!: string | string[];
  @Input() filtersStorageLocation: StorageLocation = StorageLocation.queryParams;
  @Input() disableMobileView: boolean = false;
  @Input() noPagination: boolean = false;

  @Input() persistDateRange: boolean = false;

  @Input() loadingSpinnerClass!: string | string[];
  @Input() isBusy: boolean = false;

  @Output() searchChange = new EventEmitter<SearchChangeEvent>();
  @Output() applyFilters = new EventEmitter<FilterValue[]>();
  @Output() resetFilters = new EventEmitter<FilterValue[]>();
  @Output() dateChange = new EventEmitter<DateRange>();


  @ViewChild(MatTable, { static: true }) table!: MatTable<T>;
  @ViewChild('neoTableContainer', { static: false, read: ElementRef }) neoTableContainerRef!: ElementRef;
  @ViewChild('neoInnerTable', { static: false, read: ElementRef }) neoInnerTableRef!: ElementRef;
  @ViewChild('search') searchInput!: ElementRef;

  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;
  @ContentChildren(MatHeaderRowDef) headerRowDefs!: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs!: QueryList<MatRowDef<T>>;

  @ContentChild(MatPaginator, { static: false, read: ElementRef }) paginatorRef!: ElementRef;
  @ContentChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  showSortByMenu: boolean = false;
  isSortByMenuOpen: boolean = false;
  searchApplying: boolean = false;
  page: number = 0;
  persistedFilters: FilterValue[] = [];

  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private actRoute: ActivatedRoute,
    private messagingService: MessagingService,
    private mediaObserver: MediaObserver,
    private fb: FormBuilder,
  ) { }

  static getLabelsMaxWidth(labels: string[]): string {
    let maxLen = 0;
    const separatorWidth = 0;

    labels?.forEach?.((label) => {
      if (label?.length > maxLen) {
        maxLen = label?.length;
      }
    });

    return `${9 * maxLen + separatorWidth}px`;
  }

  static createHtmlNodeFromString(htmlString: string): ChildNode | null {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  }

  static validateDateRange(absCtrl: AbstractControl): ValidationErrors {
    const form = absCtrl as unknown as FormGroup;
    const { start, end } = form.value;

    if (form.dirty && !start) {
      return { emptyFromDate: true };
    }
    if (form.dirty && !end) {
      return { emptyToDate: true };
    }
    if (form.dirty && start > end) {
      return { endDateLessThanStartDate: true };
    }
    return {};
  }

  get invalidDateRange(): boolean {
    return (
      this.dateRangeCtrl?.controls?.dateRange.hasError('emptyFromDate') ||
      this.dateRangeCtrl?.controls?.dateRange.hasError('emptyToDate') ||
      this.dateRangeCtrl?.controls?.dateRange.hasError('endDateLessThanStartDate')
    );
  }

  public get loadingSpinnerSize(): number {
    const tableContainerHeight = +this.neoTableContainerRef?.nativeElement?.getBoundingClientRect().height;
    return (tableContainerHeight > 100 ? 100 : tableContainerHeight) - 20;
  }

  @HostListener('window:click', ['$event'])
  closeSortByMenu(event: Event): void {
    const target = event.target as HTMLElement;
    if (
      !target?.matches?.('.sort-by-opener') &&
      !target?.matches?.('.sort-by-opener-icon')
    ) {
      this.isSortByMenuOpen = false;
    }
  }

  ngOnInit(): void {
    this.registerSearchChange();
    this.registerQueryParamsChange();
  }

  registerSearchChange(): void {
    let init = true;
    const sub = this.searchCtrl.valueChanges
      .pipe(debounceTime(this.searchDebounce), distinctUntilChanged())
      .subscribe(
        (searchText: string) => {
          this.persistUISettings({ searchText });
          this.searchChange.emit({ value: searchText, init });

          if ((init || this.refetchOnSearchChange) && typeof this.dataSourceFunction === 'function') {
            this.dataSourceFunction();
          }
          init = false;
        },
        (err: Error) => {
          init = false;
          this.messagingService.showError(err.message)
        }
      );
    this.subscription.add(sub);
  }

  registerQueryParamsChange(): void {
    let init = true;
    const sub = this.actRoute.queryParams.subscribe((params) => {
      this.patchValuesFromQueryParams(params, init);
      init = false;
    });
    this.subscription.add(sub);
    // init query params
    this.changeQueryParams(this.actRoute.snapshot.queryParams);
  }

  ngAfterContentInit(): void {
    this.columnDefs.forEach((columnDef: MatColumnDef) => {
      this.table.addColumnDef(columnDef);
    });
    this.headerRowDefs.forEach((headerRowDef: MatHeaderRowDef) => {
      this.table?.addHeaderRowDef(headerRowDef);
    });
    this.rowDefs.forEach((rowDef: MatRowDef<T>) => {
      this.table?.addRowDef(rowDef);
    });
  }

  ngAfterViewInit(): void {
    if (this.neoInnerTableRef) {
      if (this.paginatorRef) this.setupPaginator();
      if (this.matSortRef) this.setupSorting();
      if (!this.showSortByMenu) this.updateSortByMenuVisibility();
      this.updateUISettingsFromStorage();
    }
  }

  ngAfterViewChecked(): void {
    this.columnDefs.forEach((columnDef: MatColumnDef) => {
      if (!(this.table as any)?._columnDefsByName?.has(columnDef.name)) {
        this.table.addColumnDef(columnDef);
      }
    });
    if (this.mediaObserver.isActive(Breakpoint.ltMd)) {
      this.setupMobileViewCells();
    }
  }

  updateUISettingsFromStorage(): void {
    const uiSettings = this.getUISettings();
    if (uiSettings) {
      asapScheduler.schedule(() => {
        if (!this.noPagination && this.page) {
          this.paginator.pageIndex = this.page;
        }

        if (uiSettings?.sort) {
          const sort = uiSettings?.sort as Sort;
          NeoTableService.applySort(sort?.active, sort?.direction, this.matSortRef, { emitEvent: false });
        }

        if (!this.noPagination && uiSettings?.pagination) {
          const paginator = uiSettings?.pagination as PageEvent;
          this.paginator.pageSize = paginator.pageSize;
        }

        if ((this.persistDateRange && uiSettings?.dateRange) || this.defaultDateRange) {
          const dateRange = (this.persistDateRange && uiSettings?.dateRange) || (typeof this.defaultDateRange === 'function' ? this.defaultDateRange() : this.defaultDateRange);
          this.dateRangeCtrl.controls.dateRange.patchValue(dateRange);


          if (!this.dateRangeCtrl.controls.dateRange?.value?.start) {
            this.dateRangeCtrl.controls.dateRange.setErrors({ 'emptyFromDate': true });
          } else if (!this.dateRangeCtrl.controls.dateRange?.value?.end) {
            this.dateRangeCtrl.controls.dateRange.setErrors({ 'emptyToDate': true });
          } else if (this.dateRangeCtrl.controls.dateRange?.value?.start > this.dateRangeCtrl.controls.dateRange?.value?.end) {
            this.dateRangeCtrl.controls.dateRange.setErrors({ 'endDateLessThanStartDate': true });
          }
        }

        if (this.filtersStorageLocation === StorageLocation.localStorage && uiSettings?.filters) {
          try {
            const paramsFilters = JSON.parse(lz.decompressFromEncodedURIComponent(uiSettings.filters as string || '') || '[]') as FilterValue[];
            if (paramsFilters?.length) {
              this.persistedFilters = paramsFilters;
              this.applyFilters.emit(paramsFilters);
            }
          } catch (err) {
            this.messagingService.showError((err as Error)?.message);
          }
        }

      });
    }
  }

  persistUISettings({ searchText, sort, pagination, filters, dateRange }: PersistUISettingsParams): void {
    const uiSettings = this.getUISettings() || {};
    const qParams = {} as QueryParams;
    searchText = searchText || this.searchCtrl.value;

    if (filters !== null && filters !== undefined) {
      if (this.filtersStorageLocation === StorageLocation.localStorage) {
        uiSettings.filters = lz.compressToEncodedURIComponent(filters);
      } else {
        qParams.filters = lz.compressToEncodedURIComponent(filters);
      }
    }

    if (searchText !== null && searchText !== undefined) {
      qParams.searchText = searchText;
    }

    if (!!sort || !!this.matSortRef) {
      const { active, direction } = this.matSortRef;
      sort = sort || { active, direction };
      uiSettings.sort = sort;
    }

    if (!this.noPagination && (!!pagination || !!this.paginator)) {
      const { pageIndex, pageSize, length } = this.paginator;
      pagination = pagination || { pageIndex, pageSize, length };
      uiSettings.pagination = pagination;
      qParams.page = +pagination.pageIndex + 1;
    }

    if (this.persistDateRange && dateRange !== null && dateRange !== undefined) {
      uiSettings.dateRange = dateRange;
    }

    if (Object.values(qParams).length > 0) {
      this.changeQueryParams({ ...this.actRoute.snapshot.queryParams, ...qParams });
    }

    localStorage.setItem('table_settings_' + this.tableName, JSON.stringify(uiSettings));
  }

  changeQueryParams(qParams: QueryParams = {}): void {
    const queryParam: QueryParams = { searchText: qParams.searchText || this.searchCtrl.value };
    const snapQueryParams = this.actRoute.snapshot.queryParams;

    if (!this.noPagination) {
      queryParam.page = (+qParams.page || +(this.paginator?.pageIndex || 0) + 1)?.toString();
    }

    if (this.filtersStorageLocation !== StorageLocation.localStorage && qParams.filters) {
      queryParam.filters = qParams.filters;
    }

    if (NeoTableService.isJSONEqual(queryParam, snapQueryParams)) return;

    this.router
      .navigate(['.'], {
        relativeTo: this.actRoute,
        queryParams: NeoTableService.deleteEmptyValues({ ...snapQueryParams, ...queryParam })
      })
      .catch((err: Error) => this.messagingService.showError(err.message));
  }

  patchValuesFromQueryParams(params: QueryParams, init: boolean = false): void {
    const paramsSearchText = params.searchText || '';
    const pageIndex = params?.page ? (+params?.page - 1) : 0;

    if (paramsSearchText !== this.searchCtrl.value || init) {
      const searchText = params?.searchText || '';
      this.searchCtrl.patchValue(searchText);
    }

    if (!this.noPagination && (pageIndex || pageIndex === 0)) {
      if (this.paginator?.pageIndex !== undefined && this.paginator?.pageIndex !== null) {
        this.paginator.pageIndex = +pageIndex;
      } else {
        this.page = +pageIndex || 0;
      }
    }

    if (this.filtersStorageLocation !== StorageLocation.localStorage) {
      try {
        const paramsFilters = JSON.parse(lz.decompressFromEncodedURIComponent(params.filters as string || '') || '[]') as FilterValue[];
        if (paramsFilters?.length) {
          this.persistedFilters = paramsFilters;
          this.applyFilters.emit(paramsFilters);
        }
      } catch (err) {
        this.messagingService.showError((err as Error)?.message);
      }
    }
  }

  getUISettings(): UISettings {
    let uiSetting = {} as UISettings;
    try {
      uiSetting = JSON.parse(localStorage.getItem('table_settings_' + this.tableName) || '{}') as UISettings;
    } catch (error) {
      this.messagingService.showError(error as string);
    }
    return uiSetting;
  }

  setupSorting(): void {
    let init = true;
    this.matSortRef.sortChange.subscribe((sort: Sort) => {
      this.persistUISettings({ sort });

      if (!init && this.refetchOnSortChange && typeof this.dataSourceFunction === 'function') {
        this.dataSourceFunction();
      }
      init = false;
    });
  }

  setupPaginator(): void {
    this.renderer.appendChild(this.neoTableContainerRef.nativeElement, this.paginatorRef.nativeElement);

    const sub = this.paginator?.page?.subscribe?.(
      (event: PageEvent) => {
        this.persistUISettings({ pagination: event });

        if (this.refetchOnPagination && typeof this.dataSourceFunction === 'function') {
          this.dataSourceFunction();
        } else {
          asapScheduler.schedule(() => {
            const scrollOpts = { behavior: 'smooth', block: 'start', inline: 'nearest', };
            this.neoTableContainerRef?.nativeElement?.scrollIntoView?.(scrollOpts);
          });
        }
      },
      (err: Error) => this.messagingService.showError(err.message)
    );
    this.subscription.add(sub);
  }

  updateSortByMenuVisibility(): void {
    asapScheduler.schedule(() => {
      this.neoInnerTableRef?.nativeElement
        ?.querySelectorAll('th')
        .forEach((th: HTMLElement) => {
          if (th?.matches?.('.mat-sort-header') && !this.showSortByMenu) {
            this.showSortByMenu = true;
          }
        });
    });
  }

  setupMobileViewCells(): void {
    const allTds: HTMLElement[] = this.neoInnerTableRef?.nativeElement?.querySelectorAll('td');

    if (allTds?.length > 0) {
      const allThs: HTMLElement[] = this.neoInnerTableRef?.nativeElement?.querySelectorAll('th');
      const labels: string[] = [];
      allThs.forEach((th) => labels.push(th.innerText));
      const labelMinWidth = this.mediaObserver.isActive(Breakpoint.ltSm) ? '80px' : NeoTableComponent.getLabelsMaxWidth(labels);

      allTds.forEach((td: HTMLElement, index: number) => {
        const labelClass = 'mobile-view-header';
        const labelStyles = `min-width: ${labelMinWidth}; max-width: ${labelMinWidth}`;
        const mobileViewHeader = td?.querySelector?.(`span.${labelClass}`);

        if (!mobileViewHeader) {
          const thIndex = index % allThs?.length;
          const labelText = allThs[thIndex]?.innerText;
          const mobileViewLabelHtmlStr = `<span class="${labelClass}" style="${labelStyles}">${labelText}</span>`;
          const mobileViewLabelHtml = NeoTableComponent.createHtmlNodeFromString(mobileViewLabelHtmlStr);
          this.renderer.insertBefore(td, mobileViewLabelHtml, td.firstChild);
        } else {
          this.renderer.setStyle(mobileViewHeader, 'min-width', labelMinWidth);
          this.renderer.setStyle(mobileViewHeader, 'max-width', labelMinWidth);
        }
      });
    }
  }

  onDateRangeClose(): void {
    const { dateRange } = this.dateRangeCtrl.value;
    if (this.persistDateRange) this.persistUISettings({ dateRange });

    if (this.dateRangeCtrl.dirty && !this.invalidDateRange) {
      dateRange.start = NeoTableService.getFormattedDateStr(dateRange.start);
      dateRange.end = NeoTableService.getFormattedDateStr(dateRange.end);
      this.dateChange.emit(dateRange);

      if (this.refetchOnDateRangeChange && typeof this.dataSourceFunction === 'function') {
        this.dataSourceFunction();
      }
    }
  }

  getDateRangeCtrl(): FormGroup {
    return this.fb.group({
      dateRange: this.fb.group(
        {
          start: new FormControl(new Date()),
          end: new FormControl(new Date()),
        },
        { validators: NeoCardListComponent.validateDateRange.bind(this) }
      ),
    });
  }

  headerButtonClickHandler(index: number): void {
    const button = this.headerButtons[index];
    if (typeof button?.onClick === 'function') {
      button.onClick();
    }
  }

  applyFilter(filters: FilterValue[]): void {
    const filtersStr = JSON.stringify(filters);
    this.persistUISettings({ filters: filtersStr });
    this.applyFilters.emit(filters);

    if (this.refetchOnApplyFilters && typeof this.dataSourceFunction === 'function') {
      this.dataSourceFunction();
    }
  }

  resetAllFilter(filters: FilterValue[]): void {
    const filtersStr = JSON.stringify(filters);
    this.persistUISettings({ filters: filtersStr });
    this.resetFilters.emit(filters);

    if (this.refetchOnResetFilters && typeof this.dataSourceFunction === 'function') {
      this.dataSourceFunction();
    }
  }

  unique = (index: number): number => index;

  ngOnDestroy(): void {
    this.subscription?.unsubscribe?.();
  }
}
