import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'holidayTypes'
})
export class HolidayTypesPipe implements PipeTransform {

  public transform = (value: number): string => {
    switch (value)
    {
      case 1:
        return 'Public Holiday';
      case 2:
        return 'RDO';
      default:
        return '';
    }
  }
}