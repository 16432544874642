import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { neoMatIconPrefix } from '@app/core/models/ui.model';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  /* Usage Notes: <mat-icon svgIcon="neo_client"></mat-icon> // svgIcon="<prefix>+<iconName>"*/
  svgIcons: { [key: string]: string[] } = {
    common: [
      'approved',
      'rejected',
      'not_submitted',
      'pending_for_approval',
    ],
    sidebar: [
      'staff',
      'client',
      'projects',
      'project_calendar',
      'equipment_calendar',
      'diary_entries',
      'plant_register',
      'reporting',
      'template',
      'import',
      'export',
      'setting',
      'logo',
      'text_logo',
      'dashboard',
      'info',
      'push_pin',
      'push_unpin',
    ],
    calendar: [
      'two_staff',
      'tools',
      'filled_check_circle',
      'in_review',
      'in_progress',
      'schedule',
      'expand',
      'view_more'
    ],
    'calendar-v2': [
      'card_view_compact',
      'card_view_slim',
      'card_view_expanded',
      'more_vertical',
      'chat_round',
      'chat_round_grey',
      'calendar_red',
      'calendar_orange',
      'calendar_green',
      'equipment_drill',
      'equipment_excavator',
      'info_outline',
      'repeatable',
      'trash_bin',
      'daily_log_in_progress',
      'daily_log_submitted_for_review',
      'daily_log_approved',
      'full_time_avatar',
      'full_time_accepted_avatar',
      'full_time_rejected_avatar',
      'part_time_avatar',
      'part_time_accepted_avatar',
      'part_time_rejected_avatar',
      'sub_contractor_avatar',
      'sub_contractor_accepted_avatar',
      'sub_contractor_rejected_avatar',
      'red_exclamatory',
      'red_cross',
      'bold_pen',
      'clock_grey',
      'more_horizontal',
      'grey_cross',
      'snackbar_success',
      'snackbar_warning',
      'snackbar_error',
      'snackbar_info',
      'lock',
      'unlock',
      'red_exclamatory_hexagonal',
      'grey_search',
      'sort'
    ]
  };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  static isNeoMatIcon(name: string = ''): boolean {
    return !!name?.startsWith(neoMatIconPrefix);
  }

  registerSvgIcons(): void {
    const prefix = neoMatIconPrefix;
    for (const dirName of Object.keys(this.svgIcons)) {
      this.svgIcons[dirName].forEach((svgIcon) => {
        this.matIconRegistry.addSvgIcon(`${prefix}${svgIcon}`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../../assets/${dirName}/${svgIcon}.svg`));
      });
    }
  }
}
