import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'find'
})

export class FindPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(array: any[], value: any, valueKey: string): any {
    return array?.find(item => item[valueKey] === value);
  }
}
