
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertComponent } from '@app/shared/components/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  duration: number = 3000; // 3 Seconds
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';

  constructor(private snackBar: MatSnackBar, private domSanitizer: DomSanitizer) { }

  showSuccess(message: string, isHtml: boolean = false, title: string = 'Success!'): void {
    const data = isHtml ? { html: this.domSanitizer.bypassSecurityTrustHtml(message) } : { message };

    this.snackBar.openFromComponent(AlertComponent, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: `neo-alert-panel`,
      data: { ...data, type: 'success', title },
    });
  }


  showWarning(message: string, isHtml: boolean = false, title: string = 'Warning!'): void {
    const data = isHtml ? { html: this.domSanitizer.bypassSecurityTrustHtml(message) } : { message };

    this.snackBar.openFromComponent(AlertComponent, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: `neo-alert-panel`,
      data: { ...data, type: 'warning', title },
    });
  }

  showError(message: string, isHtml: boolean = false, title: string = 'Error!'): void {
    const data = isHtml ? { html: this.domSanitizer.bypassSecurityTrustHtml(message) } : { message };

    this.snackBar.openFromComponent(AlertComponent, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: `neo-alert-panel`,
      data: { ...data, type: 'error', title },
    });
  }
}
