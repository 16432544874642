import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { FilterType, FilterValue } from './neo-flyout-filter-value';

const LT_MD = 'screen and (max-width: 959.98px)';

@Component({
  selector: 'neo-flyout-filter',
  templateUrl: './neo-flyout-filter.component.html',
  styleUrls: ['./neo-flyout-filter.component.scss'],
})
export class NeoFlyoutFilterComponent implements OnChanges, AfterViewChecked {
  @Input() filters: FilterValue[] = [];
  @Input() closeOnReset: boolean = true;
  @Input() closeOnApply: boolean = true;
  @Input() openerButtonLabel!: string | undefined;
  @Input() openerButtonColor!: string | undefined;
  @Input() disabled: boolean | undefined = false;

  @Output() change = new EventEmitter<FilterValue[]>();
  @Output() applyFilters = new EventEmitter<FilterValue[]>();
  @Output() resetFilters = new EventEmitter<FilterValue[]>();

  filterArgs: string[] = [];
  expandedPanel: number = -1;
  originalFilters: FilterValue[] = [];
  selectAllValue: boolean = true;
  dateRange: FormGroup = new FormGroup({});
  filterTypeEnum = FilterType;

  isSelectedAll = NeoFlyoutFilterComponent.isSelectedAll;
  neoToggleChanged = NeoFlyoutFilterComponent.neoToggleChanged;
  getFilterChildren = NeoFlyoutFilterComponent.getFilterChildren;

  constructor(
    private rederer: Renderer2,
    private mediaObserver: MediaObserver,
  ) { }

  static isSelectedAll(children: FilterValue[] | undefined): boolean | null {
    const notSelected = children?.filter((child) => !child.selected)?.length;
    if (notSelected === 0) return true;
    if (notSelected === children?.length) return false;
    return null;
  }

  static neoToggleChanged(event: boolean, filter: FilterValue): void {
    filter.selected = event;
  }

  static validateDateRange(absCtrl: AbstractControl): ValidationErrors {
    const form = absCtrl as unknown as FormGroup;
    const toDate: Date = form.controls.end.value;
    const fromDate: Date = form.controls.start.value;
    if (form.dirty && !fromDate) {
      return { emptyFromDate: true };
    }
    if (form.dirty && !toDate) {
      return { emptyToDate: true };
    }
    if (form.dirty && fromDate > toDate) {
      return { endDateLessThanStartDate: true };
    }
    return {};
  }

  static getFilterChildren(filter: FilterValue): FilterValue[] {
    return filter?.children?.filter((child: FilterValue) => child.label) || [];
  }

  get invalidDateRange(): boolean {
    return this.dateRange.hasError('emptyFromDate') || this.dateRange.hasError('emptyToDate') || this.dateRange.hasError('endDateLessThanStartDate');
  }

  ngOnChanges(): void {
    this.originalFilters = JSON.parse(JSON.stringify(this.filters));
    const dateRange = this.filters.find(filter => filter.type === FilterType.dateRange)
    if (dateRange) {
      this.dateRange = new FormGroup(
        {
          start: new FormControl(dateRange.startDate),
          end: new FormControl(dateRange.endDate),
        },
        { validators: NeoFlyoutFilterComponent.validateDateRange.bind(this) }
      );
      this.dateRange.valueChanges.subscribe((value) => {
        dateRange.startDate = value.start as Date;
        dateRange.endDate = value.end as Date;
      });
    }
  }

  ngAfterViewChecked(): void {
    this.updateMobileView();
  }

  updateMobileView(): void {
    const menuElem = document.querySelector('.neo-flyout-filter-container');

    if (menuElem) {
      if (this.mediaObserver.isActive(LT_MD)) {
        this.rederer.addClass(menuElem, 'mobile-view');
      } else {
        this.rederer.removeClass(menuElem, 'mobile-view');
      }
    }
  }

  apply(event: Event): void {
    if (!this.closeOnApply) {
      event.stopPropagation();
    }
    this.applyFilters.emit(this.filters);
  }

  reset(event: Event): void {
    if (!this.closeOnReset) {
      event.stopPropagation();
    }
    this.filters = JSON.parse(JSON.stringify(this.originalFilters));
    const dateRange = this.filters.find(filter => filter.type === FilterType.dateRange);
    if (dateRange) {
      this.dateRange.patchValue({ start: dateRange?.startDate, end: dateRange?.endDate });
    }
    this.resetFilters.emit(this.filters);
  }

  selectionChanged(filterChildren: FilterValue[] | undefined): void {
    this.selectAllValue = !filterChildren?.filter((f) => f.selected === false).length;
    this.change.emit(filterChildren);
  }

  selectAll(event: MatCheckboxChange, children: FilterValue[] | undefined): void {
    children?.forEach((child) => {
      child.selected = event.checked === true;
    });
    this.change.emit(children);
  }

  unique = (index: number): number => index;
}
