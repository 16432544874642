import { Injectable } from '@angular/core';
import { User, UserRole } from '@app/core/models';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private msalService: MsalService) {
  }

  public getActiveUser(): User | null {
    const activeAccount =
      this.msalService.instance.getActiveAccount() ||
      this.msalService?.instance?.getAllAccounts?.()?.[0] || null;

    if (activeAccount === null) return null;

    const claimDetail: any | null = activeAccount?.idTokenClaims;

    return {
      userId: claimDetail.extension_NeoUserId,
      username: activeAccount?.username || '',
      name: activeAccount?.name || '',
      roles: claimDetail.extension_Roles || '',
      extensionLegacyNeoApiUrl: claimDetail.extension_LegacyNeoApiUrl,
      extensionLegacyNeoSiteUrl: claimDetail.extension_LegacyNeoSiteUrl,
      extensionOrganizationId: claimDetail.extension_OrganizationId,
      extensionOrganizationName: claimDetail.extension_OrganizationName,
      extensionTimezoneId: claimDetail.extension_TimezoneId
    };
  }

  public hasRole(userRole: UserRole): boolean {
    const currentUsersRoles = this.getRoles();
    if (currentUsersRoles === null) return false;
    return currentUsersRoles.includes(userRole);
  }
  public hasAllRoles(userRoles: UserRole[]): boolean {
    const currentUsersRoles = this.getRoles();
    if (currentUsersRoles === null) return false;
    return userRoles.every((role) => currentUsersRoles.includes(role));
  }
  public hasAnyRoles(userRoles: UserRole[]): boolean {
    const currentUsersRoles = this.getRoles();
    if (currentUsersRoles === null) return false;
    return userRoles.some((role) => currentUsersRoles.includes(role));
  }
  private getRoles(): Array<UserRole> | null {
    const user = this.getActiveUser();
    if (user === null) return null;
    return user.roles.split(',').map((x) => Number(x));
  }
}
