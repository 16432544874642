import { Point } from '@angular/cdk/drag-drop';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NeoMediaViewerHeaderButton, NeoMediaViewerHeaderButtonId, NeoMediaViewerItem } from '@app/core/models/neo-media-viewer.model';
import { MessagingService } from '@app/services/messaging.service';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import KeenSlider, { KeenSliderInstance } from "keen-slider";
import { asyncScheduler } from 'rxjs';
import { NeoMediaViewerService } from './neo-media-viewer.service';

@Component({
  selector: 'neo-media-viewer',
  templateUrl: './neo-media-viewer.component.html',
  styleUrls: ['./neo-media-viewer.component.scss']
})
export class NeoMediaViewerComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

  @ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>
  @ViewChild("thumbnailRef") thumbnailRef!: ElementRef<HTMLElement>
  @ViewChild("dialogContainerRef") dialogContainerRef!: ElementRef<HTMLElement>

  @ViewChildren("image") imagesList!: QueryList<HTMLElement>

  currentSlide: number = 1
  slider!: KeenSliderInstance;
  thumbnailSlider!: KeenSliderInstance;
  mediaSources!: NeoMediaViewerItem[];
  rotation: number = 0;
  isFullScreen: boolean = false;
  currentZoomLevel: number = 1;
  panzoom!: PanzoomObject;
  isPanning: boolean = false;
  coOrdinates!: { x: number, y: number };
  headerButtons: NeoMediaViewerHeaderButton[] = this.getHeaderButtons();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { media: any, index: number, hideHeaderButtons?: NeoMediaViewerHeaderButtonId[], style?: { [klass: string]: any }, thumbnailStyle?: { [klass: string]: any }; },
    private dialogRef: MatDialogRef<NeoMediaViewerComponent>,
    private messagingService: MessagingService,
    private neoMediaViewerService: NeoMediaViewerService,
    private renderer: Renderer2
  ) { }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(_event: KeyboardEvent): void {
    if (_event.code === "ArrowLeft") {
      this.slider.prev();
    } else if (_event.code === "ArrowRight") {
      this.slider.next();
    }
  }

  @HostListener('document:pointermove', ['$event'])
  handlePointerMove(_event: PointerEvent): void {
    if (this.isPanning) {
      const pointerPos = { x: _event.clientX, y: _event.clientY } as Point;
      const pan = NeoMediaViewerService.getUpdatedPoint(pointerPos, this.panzoom.getPan(), this.coOrdinates, this.rotation);
      this.updatePanzoomStyle(pan);
    }
  }

  @HostListener('document:pointerup', ['$event'])
  handlePointerUp(_event: PointerEvent): void {
    this.isPanning = false;
  }

  ngAfterViewInit(): void {
    this.mediaSources = this.data.media;
    this.currentSlide = this.data.index;

    asyncScheduler.schedule(() => {
      this.slider = new KeenSlider(this.sliderRef?.nativeElement, {
        initial: this.currentSlide,
        slideChanged: (slider: KeenSliderInstance): void => {
          this.currentSlide = slider.track.details.rel;
          this.updateZoomableImage(this.currentSlide);
        }
      });

      this.thumbnailSlider = new KeenSlider(
        this.thumbnailRef?.nativeElement,
        {
          initial: this.currentSlide,
          drag: true,
          slides: {
            perView: 6,
            spacing: 10
          },
        },
        [NeoMediaViewerService.thumbnailPlugin(this.slider, this.data.index)]
      );

      this.updateZoomableImage(this.currentSlide);
    });
  }

  ngAfterViewChecked(): void {
    this.headerButtons = this.getHeaderButtons();
  }

  updateZoomableImage(index: number): void {
    this.rotation = 0;
    this.currentZoomLevel = 1;
    const image = this.imagesList.find((_item, idx) => idx === index) as unknown as ElementRef;
    const imageElem = image?.nativeElement as HTMLElement;

    if (imageElem) {
      this.panzoom = Panzoom(imageElem, { minScale: 0.1, maxScale: 5, noBind: true });
      this.renderer.listen(imageElem, 'pointerdown', (_event: PointerEvent) => {
        if (this.currentZoomLevel > 1 || this.rotation % 360 !== 0) {
          // Stop dragging the image
          _event.preventDefault();
        }

        this.isPanning = true;
        this.coOrdinates = { x: _event.clientX, y: _event.clientY };
      })
    }
  }

  headerButtonClickHandler(index: number, event: Event): void {
    const button = this.headerButtons[index];
    if (typeof button?.onClick === 'function') {
      button.onClick(event);
    }
  }

  closeDialog(_event: Event): void {
    this.dialogRef.close(false);
  }

  fullScreen(_event: Event): void {
    if (!this.isFullScreen) {
      this.dialogContainerRef?.nativeElement.requestFullscreen()
        .then(() => this.isFullScreen = true)
        .catch((err: Error) => this.messagingService.showError(err.message));
    } else {
      document.exitFullscreen()
        .then(() => this.isFullScreen = false)
        .catch((err: Error) => this.messagingService.showError(err.message));
    }
  }

  async downloadImage(_event: Event): Promise<void> {
    return this.neoMediaViewerService.downloadImage(this.mediaSources[this.currentSlide]);
  }

  rotateImageRight(_event: Event): void {
    const angle = 90;
    this.rotation = (this.rotation + angle);
    this.updatePanzoomStyle();
  }

  rotateImageLeft(_event: Event): void {
    const angle = -90;
    this.rotation = (this.rotation + angle);
    this.updatePanzoomStyle();
  }

  zoomInImage(_event: Event): void {
    this.currentZoomLevel += 0.25;
    this.updatePanzoomStyle();
  }

  zoomOutImage(_event: Event): void {
    this.currentZoomLevel -= 0.25;
    this.updatePanzoomStyle();
  }

  updatePanzoomStyle({ x, y }: Point = this.panzoom.getPan()): void {
    this.panzoom.setStyle(
      'transform',
      `rotate(${this.rotation}deg) scale(${this.currentZoomLevel}) translate(${x}px, ${y}px)`
    );
  }

  unique = (index: number): number => index;

  getHeaderButtons(): NeoMediaViewerHeaderButton[] {
    return [
      {
        id: NeoMediaViewerHeaderButtonId.closeButton,
        class: "close-button",
        icon: "close",
        tooltip: 'Close',
        onClick: this.closeDialog.bind(this)
      },
      {
        id: NeoMediaViewerHeaderButtonId.fullScreenButton,
        class: "full-screen-button",
        icon: this.isFullScreen ? 'fullscreen_exit' : 'fullscreen',
        tooltip: this.isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
        onClick: this.fullScreen.bind(this)
      },
      {
        id: NeoMediaViewerHeaderButtonId.downloadButton,
        class: "download-button",
        icon: "file_download",
        tooltip: 'Download',
        onClick: this.downloadImage.bind(this)
      },
      {
        id: NeoMediaViewerHeaderButtonId.rotateRightButton,
        class: "rotate-right-button",
        icon: "rotate_right",
        tooltip: 'Rotate Right',
        onClick: this.rotateImageRight.bind(this)
      },
      {
        id: NeoMediaViewerHeaderButtonId.rotateLeftButton,
        class: "rotate-left-button",
        icon: "rotate_left",
        tooltip: 'Rotate Left',
        onClick: this.rotateImageLeft.bind(this)
      },
      {
        id: NeoMediaViewerHeaderButtonId.zoomOutButton,
        class: "zoom-out-button",
        icon: "zoom_out",
        tooltip: 'Zoom Out',
        disabled: this.currentZoomLevel <= 0.25,
        onClick: this.zoomOutImage.bind(this)
      },
      {
        id: NeoMediaViewerHeaderButtonId.zoomInButton,
        class: "zoom-in-button",
        icon: "zoom_in",
        tooltip: 'Zoom In',
        disabled: this.currentZoomLevel >= 3,
        onClick: this.zoomInImage.bind(this)
      }
    ].filter(headerBtn => !this.data.hideHeaderButtons?.includes(headerBtn.id));
  }

  ngOnDestroy(): void {
    if (this.slider) this.slider.destroy()
    if (this.thumbnailSlider) this.thumbnailSlider.destroy()
  }
}


