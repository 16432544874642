<div #neoTableContainer class="neo-table-container" ngClass.lt-sm="very-small-screen">
  <div class="loading-spinner" [ngClass]="loadingSpinnerClass" *ngIf="isBusy">
    <mat-spinner *ngIf="isBusy" [diameter]="loadingSpinnerSize"></mat-spinner>
    <div class="loader-text">Loading...</div>
  </div>

  <div class="table-container" [ngStyle.gt-sm]="{ padding: '10px' }">
    <div class="header-container">
      <div class="left-container" ngStyle.lt-md="flex-direction: column">

        <div class="header-search-container">
          <mat-form-field *ngIf="enableSearch" appearance="outline">
            <mat-label>Search</mat-label>
            <input #search matInput type="search" class="search-input" [formControl]="searchCtrl" data-test="searchText" />
            <mat-icon *ngIf="!search.value; else clearBtn;" matSuffix>search</mat-icon>

            <ng-template #clearBtn>
              <button matSuffix mat-icon-button (click)="searchCtrl.setValue('')" matTooltip="Clear" matTooltipClass="dark-tooltip">
                <mat-icon>close</mat-icon>
              </button>
            </ng-template>
          </mat-form-field>
        </div>

        <div *ngIf="enableDateRange" class="date-range-container" [ngClass.lt-md]="{ 'mobile-view': !disableMobileView }">
          <form [formGroup]="dateRangeCtrl">
            <div class="datePicker-outer-container">
              <div class="datePicker-inner-container" [ngClass]="{ 'errored': invalidDateRange }">
                <mat-date-range-input formGroupName="dateRange" (click)="$event.stopPropagation();"
                  [rangePicker]="picker">
                  <input readonly matStartDate formControlName="start" placeholder="Start date" (click)="picker.open()">
                  <input readonly matEndDate formControlName="end" placeholder="End date" (click)="picker.open()">
                </mat-date-range-input>
                <mat-datepicker-toggle color="primary" (click)="$event.stopPropagation();" matSuffix [for]="picker">
                  <mat-icon color="primary" matDatepickerToggleIcon>date_range</mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker (closed)="onDateRangeClose()" (click)="$event.stopPropagation();" #picker>
                </mat-date-range-picker>
              </div>
              <mat-error *ngIf="dateRangeCtrl.controls.dateRange.hasError('emptyFromDate')">Start Date is required*
              </mat-error>
              <mat-error *ngIf="dateRangeCtrl.controls.dateRange.hasError('emptyToDate')">End Date is required*
              </mat-error>
              <mat-error *ngIf="dateRangeCtrl.controls.dateRange.hasError('endDateLessThanStartDate')">
                End Date must be greater than 'Start Date
              </mat-error>
            </div>
          </form>
        </div>

      </div>

      <div *ngIf="enableHeaderButtons" class="header-buttons-container mat-body-2" ngStyle.xs="width: 100%">
        <div *ngFor="let button of headerButtons; let idx = index; trackBy: unique">
          <ng-container [ngSwitch]="button.type">

            <ng-container *ngSwitchCase="'filter'">
              <neo-flyout-filter class="header-button flyout-filter" [filters]="button.filterData || []"
                (applyFilters)="applyFilter($event)" (resetFilters)="resetAllFilter($event)" [openerButtonLabel]="button?.label"
                [fxHide.lt-md]="button.hideInMobileView" [disabled]="button.disabled" [openerButtonColor]="'primary'">
              </neo-flyout-filter>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <a [attr.href]="button.redirectURL" (click)="headerButtonClickHandler(idx)" [fxHide.lt-md]="button.hideInMobileView"
                [matTooltip]="button.tooltip || ''" [matTooltipClass]="button.tooltipClass || ''" [matTooltipDisabled]="button.disabled || !button.tooltip">
                <neo-button [disabled]="!!button.disabled" [buttonType]="button.variant || 'primary'" [id]="'headerButton' + button.id"
                  data-test="header-button" class="header-button" [text]="button.label" [prefixIcon]="button.icon" type="button" [style]="{ 'min-height': '36px' }">
                </neo-button>
              </a>
            </ng-container>

          </ng-container>
        </div>
      </div>
    </div>

    <button *ngIf="showSortByMenu" mat-icon-button aria-label="Sort By" class="sort-by-opener"
      [ngClass.lt-md]="{ 'mobile-view': !disableMobileView }" (click)="isSortByMenuOpen = !isSortByMenuOpen">
      <mat-icon class="sort-by-opener-icon">sort</mat-icon>
    </button>

    <div [ngClass]="tableContainerClass" [ngClass.lt-md]="{ 'mobile-view': !disableMobileView }">
      <table #neoInnerTable [hidden]="hideTable" mat-table [dataSource]="dataSource" multiTemplateDataRows
        class="main-table" [ngClass.lt-md]="{ 'mobile-view': !disableMobileView, 'show-sort-by': isSortByMenuOpen }"
        [fixedLayout]="fixedLayout">
        <ng-content></ng-content>
      </table>

      <div *ngIf="!isBusy && !dataSource.filteredData.length" class="no-data-found mat-headline-6">
        <mat-icon>search_off</mat-icon>
        <span>No records found.</span>
      </div>
    </div>

  </div>
</div>
