<mat-dialog-content>
  <div class="container" #dialogContainerRef>
    <div class="buttons-row">
      <button mat-icon-button *ngFor="let button of headerButtons; trackBy: unique; let bIdx = index" class="dark"
        [ngClass]="button?.class || ''" [matTooltip]="button.tooltip || ''" matTooltipPosition="below"
        matTooltipClass="dark-tooltip" [matTooltipDisabled]="!button.tooltip || button.tooltipDisabled || button.disabled"
        [disabled]="button.disabled" (click)="headerButtonClickHandler(bIdx, $event)">
        <mat-icon *ngIf="button.icon" class="dark">{{ button.icon }}</mat-icon>
        <span *ngIf="button.label">{{ button.label }}</span>
      </button>
    </div>

    <div class="navigation-wrapper" [ngClass]="{ 'full-screen': isFullScreen }">
      <div class="keen-slider keen-slider--horizontal" #sliderRef>
        <div *ngFor="let mediaItem of mediaSources; trackBy: unique; let idx = index">
          <div class="keen-slider__slide image-center">
            <img #image [id]="'neoImage' + idx" [src]="mediaItem.path | secureImage | async" alt="" [ngStyle]="data.style"/>
          </div>
        </div>
      </div>
      <svg class="arrow arrow--left dark" [fxHide]="currentSlide === 0" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24" (click)="slider.prev()">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
      <svg *ngIf="slider" class="arrow arrow--right dark"
        [fxHide]="slider.track.details.slides.length - 1 === currentSlide" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24" (click)="slider.next()">
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    </div>
    <div class="keen-slider thumbnail" #thumbnailRef>
      <div *ngFor="let mediaItem of mediaSources; trackBy: unique; let idx = index">
        <div class="keen-slider__slide number-slide image-thumbnail dark">
          <img [src]="mediaItem.thumbnailPath | secureImage | async" alt="" height="100px" [ngStyle]="data.thumbnailStyle"/>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
