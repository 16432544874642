import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { NeoToastData, NeoToastType } from './neo-toast.models';

@Component({
  selector: 'neo-toast',
  templateUrl: './neo-toast.component.html',
  styleUrls: ['./neo-toast.component.scss']
})
export class NeoToastComponent {

  public NeoToastType = NeoToastType;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NeoToastData,
    public snackBarRef: MatSnackBarRef<NeoToastComponent>) {
  }

  public close(): void {
    this.snackBarRef.dismiss();
  }
}
