<div class="neo-toggle-button">
  <mat-button-toggle-group [disabled]="disabled" #group="matButtonToggleGroup" [value]="value"
    [ngClass]="{ small: small === true }">
    <mat-button-toggle class="active-btn" [ngClass]="{ disabled: disabled }" [value]="true"
      (change)="valueChanged(group.value)" [disabled]="value === true">
      {{ activeLabel }}
    </mat-button-toggle>
    <mat-button-toggle class="inactive-btn" [ngClass]="{ disabled: disabled }" [value]="false"
      (change)="valueChanged(group.value)" [disabled]="value === false">
      {{ inactiveLabel }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
