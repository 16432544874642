import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import { AuthenticationService } from './authentication.service';
import { User } from '@app/core/models';
import { amplitudeApiKey, amplitudeServerZone } from '@app/core/settings/app.config';
import { NeoEventState, NeoEventStatus, NeoTrackEventTypes } from './amplitude.service.models';
import { OrganizationFeatureFlags, OrganizationFeatureFlagsList } from '@app/core/models/organization-feature.model';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  private amplitudeInstance: amplitude.Types.BrowserClient | undefined = undefined;
  private active: boolean = true;

  constructor(private authService: AuthenticationService) {
  }

  public initialise(): void {
    try {
      const apiKey: string = AmplitudeService.getApiKey();
      const options: amplitude.Types.BrowserOptions = AmplitudeService.getOptions();
      if(apiKey) {
        this.amplitudeInstance = amplitude.createInstance();
        if (this.amplitudeInstance) {
          this.amplitudeInstance.init(apiKey, undefined, options);
        }
      }
    }
    catch(e) {}
  }

  private static getApiKey(): string {
    return amplitudeApiKey();
  }

  private static getServerZone(): amplitude.Types.ServerZoneType {
    return amplitudeServerZone() as amplitude.Types.ServerZoneType;
  }

  private static getOptions(): amplitude.Types.BrowserOptions {

    // ------------------ WARNING ----------------------------
    // When testing, the page would get stuck until these were turned off by default
    // So be very carefull if they are turned back on and make sure it is tested.
    // ------------------ WARNING ----------------------------
    return {
      autocapture: {
        attribution: false,
        pageViews: true,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false,
      },
      serverZone: AmplitudeService.getServerZone()
    };
  }

  public setActiveUser(featureFlags?: OrganizationFeatureFlags): void {
    if (this.amplitudeInstance && this.authService) {
      const activeUser: User | null = this.authService.getActiveUser();
      if(activeUser) {
        this.amplitudeInstance.setUserId(activeUser.userId);
        if (this.authService) {
          const activeUser: User | null = this.authService.getActiveUser();
          if(activeUser) {
            const activeUserIdentity: amplitude.Types.Identify = new amplitude.Identify();
            activeUserIdentity.set('roles', activeUser.roles);
            activeUserIdentity.set('organisationId', activeUser.extensionOrganizationId);
            activeUserIdentity.set('organisationName', activeUser.extensionOrganizationName);
            if(featureFlags && featureFlags.data) {
              activeUserIdentity.set('featureFlagsAvailable', true);
              for (const [key, value] of Object.entries(featureFlags.data)) {
                activeUserIdentity.set(key, value as amplitude.Types.ValidPropertyType);
              }
            }
            else {
              activeUserIdentity.set('featureFlagsAvailable', false);
            }
            this.amplitudeInstance.identify(activeUserIdentity);
          }
        }
      }
    }
  }

  public track(baseEvent: string, state?: NeoEventState, status?: NeoEventStatus, postFix?: string, properties?: Record<string, any>): void {
    if(this.active && this.amplitudeInstance) {
      if (!properties) {
        properties = {};
      }
      let event = baseEvent;
      if(state) {
        event = `${event}-${state}`;
        properties[state] = state;
      }
      if(status) {
        event = `${event}-${status}`;
        properties[status] = status;
      }
      if(postFix) {
        event = `${event}-${postFix}`;
      }
      this.amplitudeInstance.track(event, properties);
    }
  }

  public trackPageView(page: string): void {
    if(this.active && this.amplitudeInstance) {
      const properties = { page: page };
      let event = NeoTrackEventTypes.PageView.toString();
      if(page) {
        event = `${event}-${page}`;
      }
      this.amplitudeInstance.track(event, properties);
    }
  }

  public reset(): void {
    if(this.active && this.amplitudeInstance) {
      this.amplitudeInstance.reset();
    }
  }
}
