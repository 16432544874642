<button mat-stroked-button *ngIf="['secondary', 'borderless'].includes(buttonType)" [color]="buttonType" [ngClass]="buttonClass" extended [disabled]="isDisabled" [ngStyle]="style">
  <mat-icon *ngIf="prefixIcon" matPrefix>{{ prefixIcon }}</mat-icon>
  <span class="text-content" [innerHTML]="textContent"></span>
  <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
</button>

<button mat-flat-button *ngIf="['primary', 'warn', 'success', 'progress'].includes(buttonType)" [color]="buttonType" [ngClass]="buttonClass" extended [disabled]="isDisabled" [ngStyle]="style">
  <mat-icon *ngIf="prefixIcon" matPrefix>{{ prefixIcon }}</mat-icon>
  <span class="text-content" [innerHTML]="textContent"></span>
  <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
</button>

<button mat-icon-button *ngIf="['icon'].includes(buttonType)" [color]="buttonType" [ngClass]="buttonClass" extended [disabled]="isDisabled" [ngStyle]="style">
  <mat-icon *ngIf="prefixIcon" matPrefix>{{ prefixIcon }}</mat-icon>
  <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
</button>
