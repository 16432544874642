/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/naming-convention */

import { Location } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { asyncScheduler } from 'rxjs/internal/scheduler/async';
import { AuthenticationService } from './authentication.service';
import { FeatureFlagService } from './featureflag.service';

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}

@Injectable({ providedIn: 'root' })
export class IntercomService {

  private renderer!: Renderer2;

  constructor(
    private authService: AuthenticationService,
    private featureFlagService: FeatureFlagService,
    private rendererFactory: RendererFactory2,
    private location: Location
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  get isClientTimesheetApproval(): boolean {
    return this.location.path()?.startsWith('/client-approve-timesheets');
  }

  get hideIntercomLauncher(): boolean {
    return JSON.parse(localStorage.getItem('hideIntercomLauncher') || 'false') as boolean;
  }

  set hideIntercomLauncher(hideLauncher: boolean) {
    localStorage.setItem('hideIntercomLauncher', JSON.stringify(hideLauncher));
  }

  static getIntercomLibScript(appId: string): string {
    return `
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/${appId}';
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    `;
  }

  async checkAndEnableIntercom(): Promise<void> {
    if (await this.featureFlagService.enableInterCom() && !this.isClientTimesheetApproval) {
      const activeUser = this.authService.getActiveUser();
      const appId = 'vb4cz5a8';
      const name = activeUser?.name || '';
      const email = activeUser?.username || '';
      const todayDate = new Date().toISOString().slice(0, 10);

      // Append intercom lib script to the head tag
      const intercomLibScript = this.renderer.createElement('script');
      intercomLibScript.text = IntercomService.getIntercomLibScript(appId);
      this.renderer.appendChild(document.head, intercomLibScript);

      // Append intercom settings as script tag to the body
      const intercomSettingsScript = this.renderer.createElement('script');
      intercomSettingsScript.text = `
        window.intercomSettings = {
          api_base: 'https://api-iam.intercom.io',
          app_id: '${appId}',
          name: '${name}', // Full name
          email: '${email}', // Email address
          created_at: '${todayDate}', // Signup date as a Unix timestamp
          hide_default_launcher: ${JSON.stringify(this.hideIntercomLauncher)}
        };
      `;
      this.renderer.appendChild(document.body, intercomSettingsScript);

      asyncScheduler.schedule(() => this.setupLauncherToggler(), 100);
    }
  }

  setupLauncherToggler(): void {
    const placeholder = document.createElement('div');
    const iconStyle = `position: fixed; z-index: 9; right: 5px; bottom: 5px; color: #334D99; cursor: pointer;`
    const iconTitle = 'Intercom Launcher';
    placeholder.innerHTML = `<span class="material-icons-outlined" style="${iconStyle}" title="Hide ${iconTitle}">chevron_right</span>`;
    const intercomToggleIcon = placeholder.firstElementChild as HTMLElement;
    placeholder.remove()

    if (intercomToggleIcon) {
      intercomToggleIcon.onclick = (ev: MouseEvent): void => {
        const hideLauncher = ev.target ? intercomToggleIcon.innerText === 'chevron_right' : this.hideIntercomLauncher;
        window.Intercom('update', { ...window.intercomSettings, hide_default_launcher: hideLauncher });
        intercomToggleIcon.innerText = hideLauncher ? 'chevron_left' : 'chevron_right';
        intercomToggleIcon.title = hideLauncher ? `Show ${iconTitle}` : `Hide ${iconTitle}`;
        this.hideIntercomLauncher = hideLauncher;
      }

      asyncScheduler.schedule(() => {
        this.renderer.appendChild(document.body, intercomToggleIcon);
        intercomToggleIcon.onclick?.({} as MouseEvent);
      }, 100);
    }
  }
}
