import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';
import * as moment from "moment-timezone";
import { NeoTableService } from '../components/neo-table/neo-table.service';

@Pipe({
  name: 'neoTimeWithoutSeconds',
})
export class NeoTimeWithoutSecondsPipe implements PipeTransform {
  constructor(private authService: AuthenticationService) { }
  /* This pipe converts the input date value according to the current Locale and Timezone.
     Input -> (date|string) -> "2022-10-17T05:57:07.8619924"
     Output -> (string) -> "11:27 AM (AEDT)" */
  transform(value?: Date | string): string {
    if (!value) return '';
    const userTimezone = this.authService.getActiveUser()?.extensionTimezoneId || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = navigator.language;
    const options: Intl.DateTimeFormatOptions | undefined = {
      hour: 'numeric', minute: 'numeric',
      hour12: true,
      timeZone: userTimezone,
    };
    const isoDateStr = NeoTableService.getISODatetimeString(value);
    return `${(new Date(isoDateStr)).toLocaleString(locale, options)} (${moment().tz(userTimezone).zoneAbbr()})`;
  }
}
