import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { asapScheduler, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppModeService } from './services/app-mode.service';
import { LoaderService } from './services/loader.service';
import { UiService } from './services/ui.service';
import { IntercomService } from './services/intercom.service';
import { FeatureFlagService } from './services/featureflag.service';
import { AuthChangeEvent, AuthenticationService } from './services/authentication.service';
import { User } from './core/models';
import { NeoBaseComponent } from './shared/components/base/neo-base.component';
import { getNeoLoggingEnabled } from './core/settings/app.config';
import { NeoTrackEventStatus, NeoTrackEventAction, NeoTrackEventComponent, NeoTrackEventLocation } from './services/amplitude.service.models';
import { NeoStatus } from './core/models/status.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent extends NeoBaseComponent implements OnInit, OnDestroy {
  public title = 'NeoOne';
  public isIframe = false;
  public _loader$!: boolean;
  private previousPath: string = '';

  constructor(
    protected injector: Injector,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private appModeService: AppModeService,
    private uiService: UiService,
    private intercomService: IntercomService,
    private featureFlagService: FeatureFlagService
  ) {
    super(injector);
    this.logInitialisation();
    this.neoLogService.log('AppComponent->constructor');
    this.registerSubscriptions();
    this.serviceInitialisations();
  }

  public ngOnInit(): void {
    this.neoLogService.log('AppComponent->ngOnInit');
    this.isIframe = window !== window.parent && !window.opener;
  }

  private logInitialisation(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const consoleLogging = params.get('neo-logging');
      this.neoLogService.isLoggingEnabled = (consoleLogging === 'true');
      if (this.neoLogService.isLoggingEnabled) {
        this.neoLogService.log('Console Logging Enabled by query param:', this.neoLogService.isLoggingEnabled);
      } else {
        // check the environment variable
        this.neoLogService.isLoggingEnabled = getNeoLoggingEnabled();
        if (this.neoLogService.isLoggingEnabled) {
          this.neoLogService.log('Console Logging Enabled by environment varaible:', this.neoLogService.isLoggingEnabled);
        } else {
          console.log('Console Logging Enabled:', this.neoLogService.isLoggingEnabled);
        }
      }
    });
  }

  private serviceInitialisations(): void {
    this.amplitudeService.initialise();
    this.uiService.registerSvgIcons();
    this.appModeService.initAppModeTracking();
    this.registerLoader();
    void this.authService.initialize();
  }

  private registerSubscriptions(): void {
    this.neoLogService.log('AppComponent->registerSubscriptions');
    this.subscribeAuthEvents();
    this.subscribeRouterEvents();
    this.subscribeFeatureFlagEvents();
  }

  private subscribeAuthEvents(): void {
    this.authService.changedEvent$
    .pipe(takeUntil(this.destroy$))
    .subscribe((authChangeEvent: AuthChangeEvent) => {
      this.neoLogService.log('AppComponent->registerSubscriptions->authChangeEvent:', authChangeEvent);
      if(authChangeEvent.user) {
        this.trackUser(authChangeEvent.user);
        this.intercomService.checkAndEnableIntercom();
      }
    });
  }

  private subscribeRouterEvents(): void {
    // this.router.events
    // .pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe((event) => {
    //   // this.neoRouteHelperService.logRouterEvent('AppComponent->router.events:', event);
    // });
  }

  private subscribeFeatureFlagEvents(): void {
    // subscribe to the feature flag service
    this.featureFlagService.status$
    .pipe(takeUntil(this.destroy$))
    .subscribe((status: NeoStatus) => {
      if (status === NeoStatus.idle) {
        this.intercomService.checkAndEnableIntercom();
      }
    });
  }

  public login(): void {
    this.amplitudeService.track(NeoTrackEventAction.View, NeoTrackEventComponent.App, NeoTrackEventLocation.Login, NeoTrackEventStatus.None);
    this.authService.login();
  }

  public logout(): void {
    this.amplitudeService.track(NeoTrackEventAction.View, NeoTrackEventComponent.App, NeoTrackEventLocation.Logout, NeoTrackEventStatus.None);
    this.authService.logout();
  }

  public editProfile(): void {
    this.authService.loginForEditProfile();
  }

  private trackUser(user: User): void {
    const featureFlags = this.featureFlagService.getOrganizationFeatureFlags();
    this.amplitudeService.setActiveUser(user, featureFlags);
  }

  private registerLoader(): void {
    this.loaderService._loading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((loaderValue: boolean) => {
        asapScheduler.schedule(() => {
          this._loader$ = loaderValue;
        });
      });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
