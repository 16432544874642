import { ComponentType } from "@angular/cdk/portal";
import { Error } from "./error.model";
import { FormFieldValue, Photo, SubFieldsGroup } from "./form-submission.model";
import { Pagination } from "./pagination.model";

export enum FieldFormat {
  string = 'String',
  boolean = 'Boolean',
  integer = 'Integer',
  decimal = 'Decimal',
}

export enum InputType {
  number = 'number',
  text = 'text'
}

export enum FieldType {
  label = 'Label',
  textbox = 'Textbox',
  checkbox = 'Checkbox',
  dropdown = 'Dropdown',
  textarea = 'Textarea',
  timepicker = 'Timepicker',
  photo = 'Photo',
  datepicker = 'Datepicker',
  signature = 'Signature',
  autoGeneratingNo = 'AutoGeneratingNo',
  repeatableSection = 'RepeatableSection',
}

export enum CheckboxValue {
  true = '1',
  false = '0'
}

export enum FormStatus {
  open = 'Open',
  closed = 'Closed',
  approved = 'Approved'
}

export enum FormAction {
  close = 'CLOSE',
  approve = 'APPROVE',
  unapprove = 'UNAPPROVE'
}

export enum FormActionButtonIcon {
  lock = 'lock',
  thumbsUp = 'thumb_up',
  thumbsDowm = 'thumb_down'
}

export enum RepetableSectionRowType {
  default = 'default',
  newRow = 'newRow',
  deleteRow = 'deleteRow',
  media = 'media'
}

export enum FormSubmissionCtrlType {
  photo = 'photo',
  signature = 'signature'
}

export enum FormSubmissionCtrlActionType {
  add = 'add',
  delete = 'delete'
}

export enum FormFieldsGroupType {
  autoGeneratingNo = 'AutoGeneratingNo',
  repeatableSection = 'RepeatableSection',
  fieldsGroup = 'FieldsGroup'
}

export interface FormFieldList {
  formFields: FormField[],
  formTemplateDetail: {
    formTemplateId: number;
    formTemplateName: string
  }
  pagination: Pagination,
  errors: Error[],
}

export interface FormField {
  formFieldId: number,
  label: string,
  fieldFormat: FieldFormat,
  fieldType: FieldType,
  isRequired: boolean,
  isDisplayLabel: boolean,
  defaultValue: string,
  sortOrder: number,
  dropdownChecklistId?: number,
  parentFormFieldId: number,
  subFields: FormField[],
  formFieldValue?: FormFieldValue,
  subFieldsGroup?: SubFieldsGroup[]
}

export interface FormFieldsGroup {
  type: FormFieldsGroupType,
  fields: FormField[]
}

export interface ColumnsConfig {
  dropdownChecklistId?: number;
}

export interface FormSubmissionMedia {
  formSubmissionCtrlType: FormSubmissionCtrlType,
  formSubmissionCtrlActionType: FormSubmissionCtrlActionType
  value?: any,
  formFieldId?: number,
  dailyLogSheetFormPhotoId?: number,
  repeatableSectionId?: number,
  rowIdx?: number,
  formFieldValueId?: number,
  mediaURL?: string
  idPatched?: boolean
}

export interface SavedPhoto {
  data: Photo,
  errors: Error[],
}

export interface FormControlRequired {
  readonly: boolean,
  isRequired: boolean,
  isInsideRepeatableSection: boolean
}

export interface CloseFormSubmission {
  data: string,
  errors: Error[],
}

export interface FormActionButton {
  label: string,
  icon: string,
  postActionStatus: string;
}

export interface ApproveFormSubmission {
  data: string,
  errors: Error[],
}

export interface ComponentDefs {
  [key: string]: ComponentType<any>
}

export interface FormOptions {
  formSubmissionId: number,
  readonly?: boolean,
  isInsideRepeatableSection?: boolean,
  hideLabel?: boolean,
  containerRefWidth?: number
}
