export enum NeoTrackEventTypes {
  AppStart = 'AppStart',
  AppStop = 'AppStop',
  PageView = 'PageView',
  Login = 'Login',
  Logout = 'Login',
  AddProject = 'AddProject',

  // Add more here as required...

}

export enum NeoEventState {
  None = '',
  Initiated = 'initiated',
  Interaction = 'interaction',
  ConfirmCancel = 'confirm-cancel',
  Cancel = 'cancel',
  Apply = 'apply',
  Unknown = 'unknown'
}
export enum NeoEventStatus {
  None = '',
  Success = 'success',
  Fail = 'fail',
  Unknown = 'unknown'
}

export interface NeoTrackEventBase {
  state: NeoEventState;
  status: NeoEventStatus;
  message: string;
}

export interface AddProjectEvent extends NeoTrackEventBase {
  // add specifics here as required and follow this pattern for more complex tracking
}



