import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';


@Pipe({
  name: 'securePdf'
})
export class SecurePdfPipe implements PipeTransform {

  constructor(private http: HttpClient) { }

  transform(url: string): Observable<SafeUrl> {
    return new Observable<SafeUrl>((observer) => {
      this.http.get(url, { responseType: 'blob' }).subscribe(response => {
        const safeURL = (URL.createObjectURL(response));
        observer.next(safeURL);
      }, () => observer.next());
      return observer;
    });
  }
}
