import { Pipe, PipeTransform } from '@angular/core';
import { CheckboxValue, FieldFormat, FieldType } from '@app/core/models/form-template.model';
import { CustomTimesheetField } from '@app/core/models/timesheet.model';

/**
 * Extracts value from customTimesheetField
 * @param: field <CustomTimesheetField>
 * @returns: value <any>
 */
@Pipe({
  name: 'timesheetFieldValue'
})
export class TimesheetFieldValuePipe implements PipeTransform {
  static toFixed(num: string): string {
    return Number(num) && Number(num) % Math.round(Number(num)) !== 0 ? Number(num).toFixed(2) : num;
  }

  // eslint-disable-next-line class-methods-use-this
  transform(field: CustomTimesheetField): any {
    // Checkbox
    if (field?.type === FieldType.checkbox) {
      return field.value === CheckboxValue.true;
    }
    // Dropdown
    else if (field?.type === FieldType.dropdown) {
      return field?.timesheetDropdownValues?.find((opt) => opt.dropdownValueId === field.value)?.value;
    }
    // Textbox
    else if (field?.type === FieldType.textbox) {
      return [FieldFormat.decimal, FieldFormat.integer].includes(field.format) ? TimesheetFieldValuePipe.toFixed(field.value) : field.value;
    }
    return field?.value;
  }
}
