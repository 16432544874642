import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FailedComponent } from '@app/pages/failed/failed.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: (): any => import('@app/layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'client-approve-timesheets',
    loadChildren: (): any => import('@app/client-approve-timesheets/client-approve-timesheets.module').then((m) => m.ClientApproveTimesheetModule),
    canActivate: [MsalGuard]
  },
  { path: 'login-failed', component: FailedComponent },
  { path: '**', redirectTo: '' },
];

const isIframe = window !== window.parent && !window.opener;

export const appRoutingModule = RouterModule.forRoot(routes, {
  // Don't perform initial navigation in iframes
  initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
});
