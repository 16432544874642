import { SafeHtml } from "@angular/platform-browser";

export enum NeoToastType {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error'
}

export class NeoToastData {
  public title: string | undefined = undefined;
  public message: string | undefined = undefined;
  public html: SafeHtml | undefined = undefined;
  public type: NeoToastType = NeoToastType.success;
  public showCloseIcon: boolean = false;
  public duration: number = 3000;
};
