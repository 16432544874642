import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Observable, of } from "rxjs";
import { Inject, Injectable } from "@angular/core";
import { AuthenticationService } from "@app/services/authentication.service";
import { NeoLogService } from '@app/services/neo-log.service';
import { NavigationService } from "@app/services/navigation.service";
import { User } from "@app/core/models";

@Injectable({
  providedIn: 'root',
})
export class NeoAuthGuard extends MsalGuard {

  constructor(
    @Inject(MSAL_GUARD_CONFIG) msalGuardConfig: MsalGuardConfiguration,
    msalBroadcastService: MsalBroadcastService,
    authService: MsalService,
    location: Location,
    router: Router,
    private neoAuthService: AuthenticationService,
    private neoLogService: NeoLogService
  ) {
    super(msalGuardConfig, msalBroadcastService, authService, location, router);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    // Bypass MSAL authentication only when the form submission is in kiosk mode
    if (NavigationService.isFormSubmissionInKioskMode(state.url)) {
      this.neoLogService.log('NeoAuthGuard->canActivate', 'Bypassing MSAL authentication for formsubmission in kiosk mode');
      return of(true);
    }

    // Check if the user is authenticated with MsalGuard
    const isAuthenticated: Observable<boolean | UrlTree> = super.canActivate(route, state);
    this.neoLogService.log('NeoAuthGuard->canActivate', isAuthenticated);
    if (!isAuthenticated) {
      this.onAuthenticationFailed();
      return isAuthenticated;
    }

    // // Role validation logic
    // const requiredRoles = route.data['roles'] as Array<string>;
    // const userRoles = this.customAuthService.getUserRoles(); // Fetch user roles

    // const hasRequiredRoles = requiredRoles.every(role => userRoles.includes(role));
    // if (!hasRequiredRoles) {
    //   // Redirect or handle unauthorized access
    //   this.router.navigate(['/unauthorized']); // Redirect to an unauthorized page
    //   return false;
    // }

    return isAuthenticated; // User is authenticated and has required roles
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const isAuthenticated: Observable<boolean | UrlTree> = super.canActivateChild(route, state);
    this.neoLogService.log('NeoAuthGuard->canActivateChild', isAuthenticated);
    if (!isAuthenticated) {
      this.onAuthenticationFailed();
      return isAuthenticated;
    }
    // check roles and feature flags here

    return isAuthenticated;
  }

  private onAuthenticationFailed(): void {
    const user: User | null = this.neoAuthService.getActiveUser();
    this.neoLogService.log('NeoAuthGuard->onAuthenticationFailed', user);
  }

}

