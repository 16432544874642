import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/core/models';
import {
  OrganizationFeatureFlags,
  OrganizationFeatureFlagsList,
} from '@app/core/models/organization-feature.model';
import { getOrganizationFeatureFlagsURL } from '@app/core/url/url.provider';
import { AuthenticationService } from '@app/services/authentication.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  user: User | null;
  featureFlagCache: OrganizationFeatureFlagsList;
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private apiService: ApiService
  ) {
    this.user = this.authenticationService.getActiveUser();
    this.featureFlagCache = {} as OrganizationFeatureFlagsList;
  }

  public async enableCalenderEquipment(): Promise<boolean> {
    return await this.isFeatureEnabled('enableCalenderEquipment')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableNewProjectCalendar(): Promise<boolean> {
    return await this.isFeatureEnabled('enableNewProjectCalendar')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async showNewCalendarByDefault(): Promise<boolean> {
    return await this.isFeatureEnabled('showNewCalendarByDefault')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async showPurchaseOrderReport(): Promise<boolean> {
    return await this.isFeatureEnabled('showPurchaseOrderReport')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async showProductivityReport(): Promise<boolean> {
    return await this.isFeatureEnabled('showProductivityReport')
      .then((isFeature) => {
        return isFeature;
      })
      .catch((e) => {
        return false;
      });
  }

  public async showMaterialReport(): Promise<boolean> {
    return await this.isFeatureEnabled('showMaterialReport')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableTaskBasedBudgeting(): Promise<boolean> {
    return await this.isFeatureEnabled('enableTaskBasedBudgeting')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async earthConstructionDayDocketReport(): Promise<boolean> {
    return await this.isFeatureEnabled('earthConstructionDayDocketReport')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async calendarSettingsTab(): Promise<boolean> {
    return await this.isFeatureEnabled('calendarSettingsTab')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableJobpacIntegration(): Promise<boolean> {
    return await this.isFeatureEnabled('enableJobpacIntegration')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableImportMenu(): Promise<boolean> {
    return await this.isFeatureEnabled('enableImportMenu')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableExportMenu(): Promise<boolean> {
    return await this.isFeatureEnabled('enableExportMenu')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async siteDiaryModule(): Promise<boolean> {
    return await this.isFeatureEnabled('siteDiaryModule')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async concretePowerBiReport(): Promise<boolean> {
    return await this.isFeatureEnabled('concretePowerBiReport')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async showKpiReport(): Promise<boolean> {
    return await this.isFeatureEnabled('showKpiReport')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async showJobCardReport(): Promise<boolean> {
    return await this.isFeatureEnabled('showJobCardReport')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableNewTimesheetReport(): Promise<boolean> {
    return await this.isFeatureEnabled('enableNewTimesheetReport')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableTimesheetInvoiceExport(): Promise<boolean> {
    return await this.isFeatureEnabled('enableTimesheetInvoiceExport')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableClientTimesheetApproval(): Promise<boolean> {
    return await this.isFeatureEnabled('enableClientTimesheetApproval')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableStaffInductionsClient(): Promise<boolean> {
    return await this.isFeatureEnabled('enableStaffInductionsClient')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableInterCom(): Promise<boolean> {
    return await this.isFeatureEnabled('enableIntercom')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableXeroPayrollSchedule(): Promise<boolean> {
    return await this.isFeatureEnabled('enableXeroPayrollSchedule')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableJobpacPayrollSchedule(): Promise<boolean> {
    return await this.isFeatureEnabled('enableJobpacPayrollSchedule')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableCsvPayrollSchedule(): Promise<boolean> {
    return await this.isFeatureEnabled('enableCsvPayrollSchedule')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async fieldWorkerGpsTimesheets(): Promise<boolean> {
    return await this.isFeatureEnabled('fieldWorkerGpsTimesheets')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableReportInvoicingTab(): Promise<boolean> {
    return await this.isFeatureEnabled('enableReportInvoicingTab')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableCompliance(): Promise<boolean> {
    return await this.isFeatureEnabled('enableCompliance')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableActivityCode(): Promise<boolean> {
    return await this.isFeatureEnabled('enableActivityCode')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableEBA(): Promise<boolean> {
    return await this.isFeatureEnabled('enableEBA')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async showClientAndJobNumberInCalendar(): Promise<boolean> {
    return await this.isFeatureEnabled('showClientAndJobNumberInCalendar')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  public async enableEmpIDonCalendar(): Promise<boolean> {
    return await this.isFeatureEnabled('enableEmpIDonCalendar')
      .then((isEnabled) => {
        return isEnabled;
      })
      .catch((e) => {
        return false;
      });
  }

  private getOrganizationFeatureFlags(): Observable<OrganizationFeatureFlags> {
    return this.http
      .get<OrganizationFeatureFlags>(getOrganizationFeatureFlagsURL())
      .pipe(
        map<OrganizationFeatureFlags, OrganizationFeatureFlags>(
          (response) => response
        ),
        catchError((error) => this.apiService.handleError(error))
      );
  }

  public async getOrganizationFeatureFlagsAsync(): Promise<OrganizationFeatureFlags> {
    return await this.getOrganizationFeatureFlags().toPromise<OrganizationFeatureFlags>();
  }

  private async isFeatureEnabled(
    featureFlagName: keyof OrganizationFeatureFlagsList
  ): Promise<boolean> {
    if (this.user === null) return Promise.resolve(false);
    // checking typeof is important here because the value can be true or false as it is of boolean type.
    if (typeof this.featureFlagCache[featureFlagName] !== 'undefined') {
      return Promise.resolve(this.featureFlagCache[featureFlagName]);
    }
    const { data } = await this.getOrganizationFeatureFlagsAsync();
    this.featureFlagCache = data;
    return Promise.resolve(!!this.featureFlagCache[featureFlagName]);
  }
}
