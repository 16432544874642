import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { NeoAuthGuard } from './guards/neo-auth.guard';
import { FailedComponent } from '@app/pages/failed/failed.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: (): any => import('@app/layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [NeoAuthGuard]
  },
  {
    path: 'client-approve-timesheets',
    loadChildren: (): any => import('@app/client-approve-timesheets/client-approve-timesheets.module').then((m) => m.ClientApproveTimesheetModule),
    canActivate: [NeoAuthGuard]
  },
  { path: 'login-failed', title: 'Neo One - Login Failed', component: FailedComponent },
  { path: 'page-not-found', title: 'Neo One - Page Not Found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found' },
];

const isIframe = window !== window.parent && !window.opener;

export const appRoutingModule = RouterModule.forRoot(routes, {
  // Don't perform initial navigation in iframes
  initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
});
