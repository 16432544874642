import { Point } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NeoMediaViewerItem, SecureImage } from '@app/core/models/neo-media-viewer.model';
import { MessagingService } from '@app/services/messaging.service';
import { KeenSliderInstance, KeenSliderPlugin } from "keen-slider";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NeoMediaViewerService {
  constructor(
    private http: HttpClient,
    private messagingService: MessagingService
  ) { }

  static thumbnailPlugin(main: KeenSliderInstance, index: number): KeenSliderPlugin {
    return (slider): any => {
      const removeActive = (): void => {
        slider.slides.forEach((slide) => {
          slide.classList.remove("active")
        })
      }

      const addActive = (idx: number): any => {
        slider?.slides?.[idx]?.classList.add("active")
      }

      const addClickEvents = (): void => {
        slider.slides.forEach((slide, idx) => {
          slide.addEventListener("click", () => {
            main.moveToIdx(idx)
          })
        })
      }

      slider.on("created", () => {
        addActive(index || 0);
        addClickEvents();

        main.on("animationStarted", (main) => {
          removeActive();
          const next = main.animator.targetIdx || 0;
          addActive(main.track.absToRel(next));
          slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
        })
      })
    }
  }

  static getUpdatedPoint(pointerPos: Point, pan: Point, coOrdinates: Point, rotation: number): Point {
    const { x, y } = pan;
    let finalX = x + (pointerPos.x - coOrdinates.x);
    let finalY = y + (pointerPos.y - coOrdinates.y);

    if (rotation % 360 === 90 || rotation % 360 === -270) {
      const tmp = finalX;
      finalX = finalY;
      finalY = -tmp;
    } else if (rotation % 360 === 180 || rotation % 360 === -180) {
      finalX = -finalX;
      finalY = -finalY;
    } else if (rotation % 360 === 270 || rotation % 360 === -90) {
      const tmp = finalX;
      finalX = -finalY;
      finalY = tmp;
    }
    return { x: finalX, y: finalY };
  }

  async downloadImage(mediaSource: NeoMediaViewerItem): Promise<void> {
    try {
      const anchorElement = document.createElement('a');
      const { filename, base64Url } = await this.getSecureImage(mediaSource.path).toPromise();
      document.body.appendChild(anchorElement);
      anchorElement.setAttribute('style', 'display: none');
      anchorElement.href = base64Url;
      anchorElement.download = filename || 'image.jpeg';
      anchorElement.click();
      window.URL.revokeObjectURL(base64Url);
      anchorElement.remove();
    } catch (err) {
      this.messagingService.showError((err as Error).message);
    }
  }

  getSecureImage(url: string): Observable<SecureImage> {
    return this.http.get(url, { observe: 'response', responseType: 'blob' })
      .pipe(map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition') || '';
        const base64Url = URL.createObjectURL(resp.body as Blob);
        const filename = contentDisposition?.split?.(';')?.[1]?.split?.('filename')?.[1]?.split?.('=')?.[1]?.trim?.();
        return { filename, base64Url };
      }))
  }
}
