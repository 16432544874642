// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authorityDomainB2C: 'neointelligenceCustomerDir.b2clogin.com',
  clientIdB2C: 'f71a2e75-46c5-4bfa-8083-a25cd6988d04',
  signUpSignInB2C:
    'https://neointelligenceCustomerDir.b2clogin.com/neointelligenceCustomerDir.onmicrosoft.com/B2C_1_signin_with_email_and_optional_mfa',
    signUpSignInB2CForPhone:
    'https://neointelligenceCustomerDir.b2clogin.com/neointelligenceCustomerDir.onmicrosoft.com/B2C_1_Passwordless_SignIn_Phone',
  editProfileB2C:
    'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
  redirectUri: '/',
  postLogoutRedirectUri: '/',
  postLogoutRedirectUriForPhone: '/client-approve-timesheets',
  isTest: false,
  msBaseUrl: 'https://msdev.neointelligence.com.au',
  neoOneBaseUrl: 'https://onedev.neointelligence.com.au',
  neoLinkBaseUrl: 'https://apidev.neointelligence.com.au/api',
  neoLinkB2CClientId: '598f4f96-0852-4c0d-86d8-3f7acc090cb3',
  appInsightsKey: '9c941cd3-d751-4947-9827-eca1749bb946',
  syncfusionKey: 'ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Vd0xjWHtfcndcQmJZ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
