export enum FieldType {
  icon = 'Icon',
  media = 'Media',
  label = 'Label',
  photo = 'Photo',
  textbox = 'Textbox',
  checkbox = 'Checkbox',
  dropdown = 'Dropdown',
  textarea = 'Textarea',
  timepicker = 'Timepicker',
  datepicker = 'Datepicker',
}

export enum FieldFormat {
  string = 'String',
  boolean = 'Boolean',
  integer = 'Integer',
  decimal = 'Decimal',
}

export enum CheckboxValue {
  true = '1',
  false = '0'
}

export interface FieldsConfig {
  label?: string;
  value?: string;
  valueKey: string;
  formattedValue?: string;
  type: FieldType;
  iconName?: string;
  svgIcon?: string;
  iconClass?: string | string[] | ((row: any) => string | string[]),
  tooltip?: string | ((row: any) => string),
  defaultValue?: string;
  format?: FieldFormat;
  isRequired?: boolean;
  sticky?: boolean;
  searchable?: boolean;
  timesheetFieldId?: number;
  timesheetFieldValueId?: number;
}

export interface TableConfig {
  dataSourceKey: string;
  colsConfig: FieldsConfig[];
}

export interface NeoCardTitleSection {
  label: string;
  valueKey?: string;
  valueFn?: (row: any) => string
}

export interface NeoCardTitle {
  left?: NeoCardTitleSection,
  right?: NeoCardTitleSection
}

export interface NeoCardFooterButtons {
  icon?: string;
  disabled?: boolean;
  tooltip: string;
  onClick?: () => void;
}
