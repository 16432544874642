<div class="alert-component" [ngClass]="data.type">

  <div class="status-icon-col">
    <div class="vertical-bar" [ngClass]="data.type"></div>

    <div class="icon-container" [ngClass]="data.type">
      <ng-container [ngSwitch]="data.type">
        <span *ngSwitchCase="'success'" class="e-icons e-large e-circle-check"></span>
        <span *ngSwitchCase="'warning'" class="e-icons e-large e-warning"></span>
        <span *ngSwitchCase="'error'" class="e-icons e-large e-circle-close"></span>
      </ng-container>
    </div>

  </div>

  <div class="alert-content">
    <div class="title">
      {{ data.title | titlecase }}
    </div>

    <div class="message">
      <ng-container *ngIf="!data.html; else html">
        <span>{{ data.message }}</span>
      </ng-container>

      <ng-template #html>
        <div [innerHtml]="data.html"></div>
      </ng-template>
    </div>
  </div>

  <div class="close-icon-col" *ngIf="!data.hideCloseIcon">
    <mat-icon (click)="close()">close</mat-icon>
  </div>

</div>
