<div>
  <neo-button id="neoFlyoutFilterOpener" buttonType="borderless" [text]="openerButtonLabel || ''" [matMenuTriggerFor]="menu"
    class="opener-button" [disabled]="!!disabled" suffixIcon="filter_alt">
  </neo-button>

  <mat-menu (click)="$event.stopPropagation();" #menu="matMenu" class="neo-flyout-filter-container" [backdropClass]="'neo-flyout-filter-backdrop'">
    <span class="arrow"></span>

    <div *ngFor="let filter of filters; let i = index; trackBy: unique">
      <ng-container *ngIf="filter?.children?.length; else filterWithoutChildren">
        <mat-accordion>
          <mat-expansion-panel #panel (click)="$event.stopPropagation();" [expanded]="expandedPanel === i" (opened)="expandedPanel = i">

            <mat-expansion-panel-header>
              <mat-panel-title (click)="$event.stopPropagation(); panel.toggle()">
                {{ filter.label }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">
              <mat-label>Search</mat-label>
              <input matInput type="search" class="search-input" [(ngModel)]="filterArgs[i]" data-id="{{filter.label}}" />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>

            <span class="select-all-container">
              <mat-checkbox data-id="select{{filter.label}}" [checked]="isSelectedAll(filter.children) === true"
                [indeterminate]="isSelectedAll(filter.children) === null" *ngIf="filter?.children?.length"
                (change)="selectAll($event, filter.children)"
                [color]="isSelectedAll(filter.children) === null ? 'warn' : 'primary'">
                Select All
              </mat-checkbox>
            </span>

            <div class="filter-options">
              <div *ngFor="let item of getFilterChildren(filter) | searchFilter : filterArgs[i]; let idx = index; trackBy: unique">
                <span>
                  <mat-checkbox *ngIf="item?.type === filterTypeEnum.checkbox" [(ngModel)]="item.selected"
                    (change)="selectionChanged(filter.children)">
                    {{ item.label }}
                  </mat-checkbox>
                </span>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>

      <ng-template #filterWithoutChildren>
        <ng-container *ngIf="!filter.type || filter.type !== filterTypeEnum.collection; else filterLabelOnly">
          <div class="filter-without-child-container-checkbox">
            <div class="filter-without-child-container-title">
              <mat-checkbox *ngIf="filter?.type === filterTypeEnum.checkbox" [(ngModel)]="filter.selected"
                (click)="$event.stopPropagation();" [labelPosition]="'before'">
                {{ filter.label }}
              </mat-checkbox>
            </div>
          </div>

          <div class="filter-without-child-container-toggle"
            *ngIf="filter?.type === filterTypeEnum.neoToggle && !filter?.children?.length">
            <span>{{ filter.label }}</span>
            <neo-toggle-button [small]="true" [activeLabel]="'Active'" [inactiveLabel]="'Inactive'"
              [value]="!!filter.selected" [disabled]="false" (valueChange)="neoToggleChanged($event,filter)"
              (click)="$event.stopPropagation();">
            </neo-toggle-button>
          </div>

          <div class="filter-without-child-container-datePicker"
            *ngIf="filter?.type === filterTypeEnum.dateRange && !filter?.children?.length">
            <span (click)="$event.stopPropagation();" (keyup)="$event.stopPropagation();">{{ filter.label }}</span>
            <div class="datePicker-outer-container">
              <div class="datePicker-inner-container" [ngClass]="{ 'errored': invalidDateRange }">
                <mat-date-range-input [formGroup]="dateRange" (click)="$event.stopPropagation();" [rangePicker]="picker">
                  <input readonly matStartDate formControlName="start" placeholder="Start date" (click)="picker.open()">
                  <input readonly matEndDate formControlName="end" placeholder="End date" (click)="picker.open()">
                </mat-date-range-input>
                <mat-datepicker-toggle color="primary" (click)="$event.stopPropagation();" matSuffix [for]="picker">
                  <mat-icon color="primary" matDatepickerToggleIcon>date_range</mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker (click)="$event.stopPropagation();" #picker></mat-date-range-picker>
              </div>
              <mat-error *ngIf="dateRange.hasError('emptyFromDate')">From date is required</mat-error>
              <mat-error *ngIf="dateRange.hasError('emptyToDate')">To date is required</mat-error>
              <mat-error *ngIf="dateRange.hasError('endDateLessThanStartDate')">'To Date' must be greater than 'From Date'
              </mat-error>
            </div>
          </div>
        </ng-container>
      </ng-template>

      <ng-template #filterLabelOnly>
          <mat-expansion-panel #panel (click)="$event.stopPropagation();" [expanded]="expandedPanel === i"
            (opened)="expandedPanel = i">
            <mat-expansion-panel-header>
              <mat-panel-title (click)="$event.stopPropagation(); panel.toggle()">
                {{ filter.label }}
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
      </ng-template>
    </div>

    <div>
      <div class="footer-action-buttons-container">
        <neo-button (click)="reset($event)" data-set="resetAll" buttonType="secondary" text="Reset All" type="button"></neo-button>
        <neo-button [disabled]="invalidDateRange" (click)="apply($event)" data-set="apply" buttonType="primary" text="Apply" type="button"></neo-button>
      </div>
    </div>
  </mat-menu>
</div>
