import { Injectable } from '@angular/core';
import { MatSort, MatSortHeader, SortDirection } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import * as lz from 'lz-string';
import { FilterValue } from '../neo-flyout-filter/neo-flyout-filter-value';
import { QueryParams } from './neo-table-value';

@Injectable({
  providedIn: 'root'
})
export class NeoTableService {

  constructor(private actRoute: ActivatedRoute) { }

  static applySort(active: string, direction: SortDirection, sortRef: MatSort, { emitEvent = true } = {}): void {
    sortRef.active = active;
    sortRef.direction = (direction?.toLowerCase() === 'asc' ? 'desc' : 'asc') as SortDirection;
    const _sortHeader = sortRef.sortables.get(active) as MatSortHeader;
    _sortHeader?._handleClick?.();
  }

  static isJSONEqual(obj1: any, obj2: any): boolean {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
    let isEqual = true;
    for (const key in obj1) {
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        isEqual = NeoTableService.isJSONEqual(obj1[key], obj2[key]);
      }
      if (obj1[key]?.toString() !== obj2[key]?.toString()) {
        isEqual = false;
        break;
      }
    }
    return isEqual;
  }

  static getFullName(row: any, keys = ['firstName', 'middleName', 'lastName']): string {
    const valuesArr: string[] = [];
    keys.forEach(key => { row[key] && valuesArr.push(row[key]?.toString()) });
    return valuesArr.filter((str) => str).join(' ');
  }

  static hasValue(fromArr: string[] | boolean[], value: string | boolean): boolean {
    return fromArr.includes(value as never);
  }

  static hasValueInArray(fromArr: string[] | boolean[], value: string[] | string): boolean {
    if (!Array.isArray(value))
      value = [value];
    return fromArr.some((test) => {
      return value.indexOf(test as string) > -1
    });
  }

  static hasString(value: string, searchStr: string): boolean {
    return value?.toLowerCase?.()?.includes?.(searchStr?.toLowerCase());
  }

  static isDatetimeInRow(row: any, searchStr: string, keysConfig: { id: string, pipe: any }[]): boolean {
    const searchableColValues = keysConfig.map((keyConf) => keyConf.pipe ? (keyConf.pipe.transform(row[keyConf.id]) as string) : (row[keyConf.id] as string));
    return searchableColValues.some(colVal => NeoTableService.hasString(colVal, searchStr));
  }

  static isStringInRow(row: any, searchStr: string, keys: string[] = Object.keys(row)): boolean {
    const searchableColValues = keys.map(key => row[key] as string);
    return searchableColValues.some(colVal => NeoTableService.hasString(colVal, searchStr));
  }

  static isEllipsified(element: HTMLElement): boolean {
    return element?.classList?.contains?.('ellipsified');
  }

  static deleteEmptyValues(params: QueryParams): QueryParams {
    const res = {} as QueryParams;
    Object.keys(params).forEach((key) => {
      if (params[key] || params[key] === 0) {
        res[key] = params[key];
      }
    });
    return res;
  }

  static getFormattedDateStr(dateStr: string | Date): string {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const paddedMonth = month < 10 ? `0${month}` : month;
    const day = date.getDate();
    const paddedDay = day < 10 ? `0${day}` : day;
    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  static getISODatetimeString(date: string | Date): string {
    const isoDatetimePattern = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?)$/;

    if (date instanceof Date) return date.toISOString();

    if (isoDatetimePattern.test(date) && !date.endsWith('Z')) return `${date}Z`;

    try {
      return new Date(date).toISOString();
    } catch (err) {
      return date;
    }
  }

  getFiltersFromQueryParams(actRoute: ActivatedRoute = this.actRoute): FilterValue[] {
    return JSON.parse(
      lz.decompressFromEncodedURIComponent(actRoute?.snapshot?.queryParams?.filters as string || '') || '[]'
    ) as FilterValue[];
  }
}
