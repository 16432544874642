import { Overlay } from '@angular/cdk/overlay';
import { Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Breakpoint } from '@app/core/models/app-mode.model';
import { SiteDiaryPhoto, SiteDrawing } from '@app/core/models/diary-entries.model';
import { NeoDocType, NeoMediaViewerItem } from '@app/core/models/neo-media-viewer.model';
import { LoaderService } from '@app/services/loader.service';
import { defaultPDFThumbnail } from '@app/shared/custom-pipes/loading';
import { NeoShortDatePipe } from '@app/shared/custom-pipes/neo-short-date.pipe';
import { SecurePdfPipe } from '@app/shared/custom-pipes/securePdf.pipe';
import { Subscription } from 'rxjs';
import { NeoMediaViewerComponent } from '../neo-media-viewer/neo-media-viewer.component';
import { NeoTableComponent } from '../neo-table/neo-table.component';
import { NeoTableService } from '../neo-table/neo-table.service';
import { FieldType, FieldsConfig, NeoCardFooterButtons, NeoCardTitle } from './neo-card-values';


@Component({
  selector: 'neo-card',
  templateUrl: './neo-card.component.html',
  styleUrls: ['./neo-card.component.scss']
})
export class NeoCardComponent implements OnDestroy {

  @Input() dataSource!: any;
  @Input() title!: NeoCardTitle;
  @Input() fieldConfig!: FieldsConfig[];
  @Input() neoCardFooterButtons: NeoCardFooterButtons[] = [];
  @Input() enableNeoCardFooterButtons: boolean = false;

  @ViewChild('row', { static: false, read: ElementRef }) row!: ElementRef;

  pdfThumbnail = defaultPDFThumbnail;
  neoDocType = NeoDocType;
  fieldTypeEnum = FieldType;

  isEllipsified = NeoTableService.isEllipsified;

  private subscription: Subscription = new Subscription();

  constructor(
    private mediaObserver: MediaObserver,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private overlay: Overlay,
    private securePdfPipe: SecurePdfPipe,
    private loaderService: LoaderService,
    private neoShortDatePipe: NeoShortDatePipe
  ) { }

  public get leftTitle(): string {
    if (typeof this.title.left?.valueFn === 'function') {
      return this.title.left?.valueFn(this.dataSource);
    }
    return this.title.left?.valueKey ? this.dataSource?.[this.title.left?.valueKey] as string : '';
  }

  public get rightTitle(): string {
    if (typeof this.title.right?.valueFn === 'function') {
      return this.title.right?.valueFn(this.dataSource);
    }
    return this.title.right?.valueKey ? this.dataSource?.[this.title.right?.valueKey] as string : '';
  }

  setLabelWidthDynamically(): void {
    const allLabels: HTMLElement[] = this.row?.nativeElement?.querySelectorAll('.label');
    if (allLabels?.length > 0) {
      const labels: string[] = [];
      allLabels.forEach((label) => labels.push(label.innerText));
      const labelMinWidth = NeoTableComponent.getLabelsMaxWidth(labels);

      allLabels.forEach((label: HTMLElement) => {
        this.renderer.setStyle(label, 'min-width', labelMinWidth);
        this.renderer.setStyle(label, 'max-width', labelMinWidth);
      });
    }
  }

  handleThumbnailClick(medias: (SiteDrawing | SiteDiaryPhoto)[], index: number, type: NeoDocType): void {
    if (type.includes(NeoDocType.image)) {
      const filteredMedias = medias.filter(media => media?.type?.includes(NeoDocType.image))
        .map(media => ({ path: media.path, thumbnailPath: media.thumbnailPath }));
      this.openMediaViewer(filteredMedias, index);
    } else {
      this.openPDfViewer(medias[index].path);
    }
  }

  openMediaViewer(medias: NeoMediaViewerItem[], index: number): void {
    // open a modal with gallery view
    const dialogRef = this.dialog.open(NeoMediaViewerComponent, {
      data: {
        media: medias,
        index
      },
      width: '100vw',
      maxWidth: '100vw',
      panelClass: ['no-overflow', 'neo-media-viewer'],
      backdropClass: 'neo-backdrop',
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });

    const sub = this.mediaObserver
      .asObservable()
      .subscribe((change: MediaChange[]) => {
        if (change[2]?.mqAlias === Breakpoint.ltMd) {
          dialogRef.updateSize('100vw', '90vh');
        } else {
          dialogRef.updateSize('100vw', '100vh');
        }
      });
    this.subscription.add(sub);

    const dialogCloseSub = dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
      }
    });
    this.subscription.add(dialogCloseSub);
  }

  openPDfViewer(url: string): void {
    this.loaderService.startLoading();
    this.securePdfPipe.transform(url).subscribe((res: any) => {
      window.open(res);
      this.loaderService.stopLoading();
    });
  }

  unique = (index: number): number => index;

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
